'use strict';

const debounce = require('lodash/debounce');

const thresholdValue = 0.1;

/**
 * Pushes the event to the datalayer
 * @param {string} event - Datalayer event
 * @param {object} target - Target html element
 */
function pushInspirationTileEvent(event, target) {
    const gtmTile = $(target).attr('data-gtm-tile');

    if (window.dataLayer && gtmTile) {
        window.dataLayer.push({
            event,
            insert_type: 'single tile',
            insert_name: gtmTile
        });
    }
}

const triggerInspirationImpression = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.intersectionRatio <= thresholdValue) {
            return;
        }
        pushInspirationTileEvent('view_insert', entry.target);

        observer.unobserve(entry.target);
    });
};

const handleInspirationImpressions = () => {
    const targets = document.querySelectorAll('.inspiration-tile, .inspiration-tile-image');

    if ('IntersectionObserver' in window) {
        try {
            const intersectionObserver = new IntersectionObserver(
                function (entries) {
                    triggerInspirationImpression(entries, intersectionObserver);
                },
                {
                    threshold: [thresholdValue]
                }
            );

            targets.forEach((target) => {
                intersectionObserver.observe(target);
            });
        } catch (error) {
            // console.log('Error initializing IntersectionObserver', error);
        }
    }
};

const inspirationClickHandler = (e) => {
    try {
        pushInspirationTileEvent('select_insert', e.currentTarget);
    } catch (error) {
        // console.log('Error handling click impression inspiration tile', error);
    }
};

const handleInspirationClicks = () => {
    $('.inspiration-tile-cta , .inspiration-tile-image').on('click', debounce(inspirationClickHandler, 300));
};

const init = () => {
    handleInspirationImpressions();
    handleInspirationClicks();
};

module.exports = {
    init: init
};
