'use strict';

const debounce = require('lodash/debounce');

const thresholdValue = 0.5;
const endpointClickReporting = 'resource/second-c/';
const endpointImpressionReporting = 'resource/first-i/';
const baseUrl = $('.citrusAd div[data-citrusad-svc-url]').attr('data-citrusad-svc-url');
const trackedClass = 'js-citrus-tracked';

const callCitrusTracking = (citrusAdId) => {
    const url = baseUrl + endpointImpressionReporting + citrusAdId;
    fetch(url);
};

const handleCarouselImpressions = (citrusAdId, currentTarget) => {
    const config = { attributes: true };
    const callback = (mutations, obs) => {
        mutations.forEach((mutation) => {
            const mutationTarget = $(mutation.target);
            if (mutation.attributeName === 'class' && mutationTarget.hasClass('active') && !mutationTarget.hasClass(trackedClass)) {
                mutationTarget.addClass(trackedClass);
                callCitrusTracking(citrusAdId);
            } else if (mutationTarget.hasClass(trackedClass)) {
                obs.disconnect();
            }
        });
    };
    const mObserver = new MutationObserver(callback);
    mObserver.observe(currentTarget.closest('.owl-item'), config);
};

const triggerCitrusImpression = (entries, observer) => {
    entries.forEach((entry) => {
        if (entry.intersectionRatio <= thresholdValue) {
            return;
        }

        const currentTarget = entry.target;
        const $target = $(currentTarget);
        const citrusAdId = $target.attr('data-citrusad-id');
        if (!citrusAdId || citrusAdId === 'null') return;

        if ($target.hasClass('citrusad-banner-carousel-item') || $target.parents('.js-homepage-citrus-ads').length) {
            const $carouselSlide = $target.closest('.owl-item');

            if ($carouselSlide.hasClass('active') && !$carouselSlide.hasClass(trackedClass)) {
                callCitrusTracking(citrusAdId);
                $carouselSlide.addClass(trackedClass);
            } else {
                handleCarouselImpressions(citrusAdId, currentTarget);
            }
        } else {
            callCitrusTracking(citrusAdId);
        }

        observer.unobserve(currentTarget);
    });
};

const handleCitrusImpressions = () => {
    const targets = document.querySelectorAll('.js-citrusad-tracking');

    if ('IntersectionObserver' in window) {
        try {
            const intersectionObserver = new IntersectionObserver(
                function (entries) {
                    triggerCitrusImpression(entries, intersectionObserver);
                },
                {
                    threshold: [thresholdValue]
                }
            );

            targets.forEach((target) => {
                intersectionObserver.observe(target);
            });
        } catch (error) {
            // console.log('Error initializing IntersectionObserver', error);
        }
    }
};

const citrusClickHandler = (e) => {
    try {
        const currentTarget = $(e.currentTarget);
        currentTarget.off('click'); // Track click only once

        const citrusAdId = currentTarget.attr('data-citrusad-id');
        if (!citrusAdId || citrusAdId === 'null') return;

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'select_insert',
                insert_type: 'citrus',
                insert_name: currentTarget.attr('data-item-list-name')
            });
        }

        const url = baseUrl + endpointClickReporting + citrusAdId;
        fetch(url, {
            keepalive: true
        });
    } catch (error) {
        // console.log('Error handling click impression citrusAd', error);
    }
};

const handleCitrusClicks = () => {
    $('.js-citrusad-tracking').on('click', debounce(citrusClickHandler, 300));
};

const init = () => {
    handleCitrusImpressions();
    handleCitrusClicks();
};

module.exports = {
    init: init
};
