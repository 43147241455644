'use strict';

var debounce = require('lodash/debounce');
var cart = require('../cart/cart');
var miniCartPreview = require('../components/miniCartPreview');
var { removeProductFromCart } = require('../product/productUtils');

const updateMiniCart = (itemsCount, totalPrice) => {
    const $minicart = $('.minicart');
    const $minicartQuantity = $minicart.find('span.minicart-quantity');
    const $minicartPrice = $minicart.find('.minicart-price');
    const $minicartLink = $minicart.find('.minicart-link');

    if ($.isNumeric(itemsCount)) {
        $minicartQuantity.text(itemsCount);
        $minicartPrice.text(totalPrice);
        $minicartLink.attr({
            'aria-label': itemsCount,
            title: itemsCount
        });

        if (itemsCount === 0) {
            $minicartLink.addClass('no-items');
        } else {
            $minicartLink.removeClass('no-items');
        }
    }
};

module.exports = {
    cart: () => {
        cart();
    },
    miniCartUpdate: () => {
        const $minicart = $('.minicart');
        const $minicartLink = $minicart.find('.minicart-link');

        $('.minicart').on('count:update', function (event, count) {
            if (count && count.cart) {
                updateMiniCart(count.cart.items.length, count.cart.totals.subTotal);
            }
        });

        $('body').on('cart:update', function (event, data) {
            if (data && data.totals) {
                updateMiniCart(data.items.length, data.totals.subTotal);
            } else if (data && data.basket) {
                updateMiniCart(data.basket.items.length, data.basket.totals.subTotal);
            }
        });

        $('.minicart, body').on('cart:update count:update', function () {
            $minicartLink.removeClass('loading');
        });
    },

    // add the spinner on the minicart
    showMiniCartSpinner: () => {
        const $minicartLink = $('.minicart-link');
        $('body').on('product:beforeAddToCart cart:beforeUpdate', function () {
            $minicartLink.addClass('loading');
        });
    },

    openRemoveAllModal: () => {
        $('body').on('click', '.js-remove-popup-button', function () {
            const actionUrl = $(this).attr('data-action');

            $.ajax({
                url: actionUrl,
                method: 'GET',
                dataType: 'html',
                /**
                 * Handles the success response of the search address AJAX call.
                 * @param {Object} data - The response object from the AJAX call.
                 */
                success: function (data) {
                    if (!$('#removeAllProductsModal').length) {
                        $('body').append(data);
                    }
                    $('#removeAllProductsModal').modal('show');
                },
                error: (error) => {
                    console.error('Error fetching template:', error); // eslint-disable-line
                }
            });
        });
    },

    removeProductFromMiniCart: () => {
        $('body').on('click', '.js-cart-delete-confirmation-btn', function () {
            var $btn = $(this);
            const $dialog = $('.mini-cart-preview .modal-dialog');

            $btn.attr('disabled', true);
            if ($dialog.length === 0) {
                // spinner on cart page
                $.spinner().start();
            } else {
                // spinner on minicart preview modal
                $dialog.spinner().start();
            }
            removeProductFromCart($btn, 'product-tile', 'product')
                .then(function () {})
                .catch(() => {
                    $btn.removeAttr('disabled');
                })
                .finally(() => {
                    $.spinner().stop();
                });
        });
    },

    disableMiniCartOnHover: () => {
        $('.minicart').off('mouseenter focusin touchstart');
    },

    handleAddOrRemoveProductOnMiniCart: () => {
        const $body = $('body');
        const refreshMiniCart = () => {
            const modalData = $('#miniCartPreview').data('bs.modal') || {};

            if (modalData._isShown) {
                miniCartPreview.methods.openMiniCart(false, true);
            }
        };

        $body.on('product:afterAddToCart product:afterRemoveFromCart', function () {
            refreshMiniCart();
        });
    },

    baseMiniCart: () => {
        $('.minicart').on('count:update', function (event, count) {
            if (count && $.isNumeric(count.cart.items.length)) {
                $('.minicart .minicart-quantity').text(count.cart.items.length);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
            }
        });

        const hideHandler = (e) => {
            if ($('.minicart').has(e.target).length <= 0) {
                $('.minicart .popover').removeClass('show');
            }
        };

        $('body').on('click', hideHandler);
        document.body.addEventListener('touchstart', debounce(hideHandler, 10), { passive: true });

        $('.minicart').on('mouseleave focusout', function (event) {
            if (
                (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
                (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
                $('body').hasClass('modal-open')
            ) {
                event.stopPropagation();
                return;
            }
            $('.minicart .popover').removeClass('show');
        });
        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                window.location.reload();
            }
        });
    }
};
