'use strict';

const { prepareRefreshEventData, refreshComponent, DATA_LAYER_CONSTANTS } = require('./shippingUtils');

/**
 * Set active states  for day tiles and first available time group tabs
 * @param {*} $selectedTile - Selected tile selector
 */
const selectSlotDayTile = ($selectedTile) => {
    var slotDayKey = $selectedTile.data('slot-key');

    $('.time-slot-tiles .visual-btn').removeClass('active');
    $selectedTile.addClass('active');

    var $timeSlotContainer = $('.time-slot-selector');
    $timeSlotContainer.find('.time-slot-group-wrapper').addClass('d-none');
    var $selectedTabWrapper = $timeSlotContainer.find(`#js-time-slot-tab-${slotDayKey}`);
    $selectedTabWrapper.removeClass('d-none');

    const $firstAvailableGroupTab = $selectedTabWrapper.find('.nav-tabs .nav-item a.nav-link:not(.disabled)').first();
    $firstAvailableGroupTab.tab('show');
};

module.exports = {
    showTimeSlotModal: () => {
        $('body').on('click', '.js-show-time-slot-modal', (e) => {
            e.preventDefault();
            var $modal = $('#timeSlotModal');
            var url = $modal.data('url');
            var cutOffTimeAlert = $('.error-messaging.cut-off-time-alert');
            $.ajax({
                url: url,
                type: 'GET',
                /**
                 * Handles the success response of the slot selector component AJAX call.
                 * @param {Object} responseHTML - The response object from the AJAX call.
                 */
                success: function (responseHTML) {
                    $modal.find('.modal-body').empty().append(responseHTML);
                    $modal.detach().appendTo('body').modal('show');
                    if (!cutOffTimeAlert.hasClass('d-none')) {
                        cutOffTimeAlert.addClass('d-none');
                    }
                    var storeID = $('.time-slot-selector').attr('data-store-id');
                    var shippingMethodID = $('.time-slot-selector').attr('data-shipping-method-id');
                    window.dataLayer.push({
                        event: DATA_LAYER_CONSTANTS.SLOT_SCREEN_EVENT,
                        slot_picker_screen: DATA_LAYER_CONSTANTS.SELECT_SLOT,
                        store_id: storeID,
                        shipping_tier: shippingMethodID === DATA_LAYER_CONSTANTS.HOME_DELIVERY_ID ? DATA_LAYER_CONSTANTS.HOME_DELIVERY : DATA_LAYER_CONSTANTS.STORE_COLLECTION
                    });
                }
            });
        });
    },

    showTimeSlotTabs: () => {
        $('body').on('click', '.js-select-time-slot-day', function (e) {
            e.preventDefault();
            var $selectedTile = $(this).closest('a.js-select-time-slot-day');
            selectSlotDayTile($selectedTile);
        });
    },

    selectTimeSlot: () => {
        $('body').on('click', '.js-select-time-slot', function (e) {
            e.preventDefault();
            const $target = $(this).closest('a.js-select-time-slot');
            var url = $target.data('href');

            var slotDetails = $target.data('slot-details');
            var isCheckoutPage = $('#checkout-main').length;
            var $timeSlots = $('.js-select-time-slot');
            if (isCheckoutPage) {
                $target.addClass('d-none');
                $target.siblings('.last-slot-label').addClass('d-none');
                $target.siblings('.custom-spinner').removeClass('d-none');
                $target.parents('.time-slot-list-item').addClass('spinner-active');
                $timeSlots.addClass('click-disabled');
            }
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: slotDetails,
                /**
                 * Handles the success response of the slot selector component AJAX call.
                 */
                success: function () {
                    $('body').trigger('shippingSummaryTimeSlot:refresh');
                    $('body').trigger('headerTimeSlot:refresh');
                    $('body').trigger('checkoutTimeSlot:refresh');
                    $('#timeSlotModal').modal('hide');
                }
            });
        });
    },

    refreshTimeSlotComponents: () => {
        $('body').on('shippingSummaryTimeSlot:refresh', () => {
            const $wrapper = $('.js-summary-time-slot-wrapper');
            const data = prepareRefreshEventData($wrapper);
            refreshComponent(data);
        });
        $('body').on('headerTimeSlot:refresh', () => {
            const $wrapper = $('.header-time-slot-wrapper');
            const data = prepareRefreshEventData($wrapper);
            refreshComponent(data);
        });

        $('body').on('checkoutTimeSlot:refresh', () => {
            // Refresh slot selector component on checkout steps
            const $wrapper = $('.js-checkout-slot-selector-wrapper');
            const data = prepareRefreshEventData($wrapper);
            refreshComponent(data);

            // Refresh slot summary component on checkout steps
            const $summaryWrapper = $('.js-checkout-slot-summary-wrapper');
            const summaryData = prepareRefreshEventData($summaryWrapper);
            refreshComponent(summaryData);
        });
    }
};
