'use strict';

let timeout = null;

/**
 * Display Notification Message
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data) {
    $.spinner().stop();
    let status = data.success ? 'success' : 'warn';
    const isMobile = app.isMobile();

    if ($('.add-to-wishlist-messages').length === 0) {
        $(isMobile ? 'nav' : 'body').append(`<div class="add-to-wishlist-messages ${isMobile ? 'mobile' : ''}"></div>`);
    }

    $('.add-to-wishlist-messages').append(`
        <div class="add-to-wishlist-alert ${status} ">
            <div class="notification-text-wrapper">
                <i class="notification-${status}-icon"></i>
                <div class="notification-text">
                    <div class="notification-text-title" >${data.msg}</div>
                    <div class="notification-text-subtitle">${data.details}</div>
                </div>
            </div>
            <i class="icon cross"></i>
        </div>`);

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(function () {
        $('.add-to-wishlist-messages').remove();

        timeout = null;
    }, 5000);

    $('.add-to-wishlist-messages .icon.cross').click(function () {
        $('.add-to-wishlist-alert').addClass('d-none');
    });
}

/**
 * Toggle wishlist icon state
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function toggleIconState(data, icon) {
    $.spinner().stop();
    if (data.success) {
        if (icon.hasClass('heart')) {
            icon.removeClass('heart').addClass('heart-active__animated');
        } else {
            icon.removeClass('heart-active__animated').addClass('heart');
        }
    }
}

module.exports = {
    toggleWishlistState: () => {
        $('body').on('click', '.js-product-wishlist-btn', function (e) {
            e.preventDefault();
            var url = $(this).data('remove-url');
            var icon = $(this).find($('i'));
            var pid = $(this).data('pid');

            if (icon.hasClass('heart')) {
                // empty heart icon : add to wishlist
                url = $(this).data('add-url');
            }

            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                /**
                 * Handles the success response of the slot selector component AJAX call.
                 * @param {Object} data - The response object from the AJAX call.
                 */
                success: function (data) {
                    toggleIconState(data, icon);
                },
                /**
                 * Error callback
                 * @param {Object} err - Error obj
                 */
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        $.spinner().stop();
                    }
                }
            });
        });

        $('body').on('click', '.order-details-add-to-wishlist', function (event) {
            event.preventDefault();
            var url = $(this).data('add-to-wishlist-url');
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'POST',
                /**
                 * Success callback
                 * @param {Object} data - Response Data
                 */
                success: function (data) {
                    $.spinner().stop();
                    displayMessage(data);
                },
                /**
                 * Error callback
                 * @param {Object} error - Error message to display
                 */
                error: function (error) {
                    displayMessage(error);
                }
            });
        });
    }
};
