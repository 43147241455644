'use strict';

module.exports = {
    /**
     * Load Gigya SDK by dynamically creating a script element and appending it to the document head.
     * @param {JQuery} $targetElem - target element having sdk url
     * @param {Function} callbackFn - Callback Function on gigya load
     */
    loadGigya: ($targetElem, callbackFn) => {
        if (!$targetElem) return;
        // Retrieve values from the HTML DOM
        const url = $targetElem.dataset.sdkUrl;

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = url;

        // When the script loads, call callbackFn
        s.onload = function () {
            callbackFn();
        };

        document.getElementsByTagName('head')[0].appendChild(s);
    }
};
