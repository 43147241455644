'use strict';

/* globals google */

/* eslint-disable no-use-before-define */

const debounce = require('lodash/debounce');

const { getModalHtmlElement, getInfoWindowHtmlElement, getStoresListHtmlElement, parseHtml, appendSelectedAddress, DATA_LAYER_CONSTANTS } = require('./shippingUtils');

var geocoder, map;
var markers = [];
var ajaxCallTriggered = false;
var storesToFetchAvailability = new Set([]);
var storeAvailabilityPollingInitiated = false;
var storeAvailabilityPollingIntervalId;
const STORE_AVAILABILITY_POLLING_INTERVAL = 1000;
const MAX_HTTP_CLIENT_REQUESTS = 10;
const SCROLL_DEBOUNCE = 100;

/**
 * Renders the map markers on the map
 */
function renderLocationMarkers() {
    markers = [];
    let mapDiv = $('.map-canvas').attr('data-locations');
    mapDiv = JSON.parse(mapDiv);

    const bounds = new google.maps.LatLngBounds();
    renderMapMarker(mapDiv, bounds);
    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (mapDiv && mapDiv.length !== 0) {
        map.fitBounds(bounds);
    }
}

/**
 * Uses google maps api to render a map
 */
function maps() {
    geocoder = new google.maps.Geocoder();
    geocoder.geocode(
        {
            address: $('#address-search-input').data('preferred-address')
        },
        function (results, status) {
            var preferredAddress = results && results[0].geometry.location;

            var mapOptions = {
                scrollwheel: false,
                zoom: 4,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: false,
                disableDefaultUI: app.isMobile()
            };

            if (status === google.maps.GeocoderStatus.OK) {
                mapOptions.center = preferredAddress;
            }

            map = new google.maps.Map($('.map-canvas')[0], mapOptions);
            renderLocationMarkers();

            // eslint-disable-next-line no-new
            new google.maps.Marker({
                position: preferredAddress,
                map: map,
                icon: $('.map-canvas').data('marker-icon-pin')
            });

            setTimeout(function () {
                map.setCenter(preferredAddress);
            }, 100);

            if (app.isDesktop()) {
                // Zoom In
                const zoomInButton = document.createElement('button');
                const zoomInButtonIcon = document.createElement('i');
                zoomInButtonIcon.classList.add('icon');
                zoomInButtonIcon.classList.add('icon-plus');
                zoomInButtonIcon.classList.add('fs-icon-xs');
                zoomInButton.append(zoomInButtonIcon);
                zoomInButton.classList.add('custom-map-zoom-in-button');
                zoomInButton.addEventListener('click', (e) => {
                    map.setZoom(map.getZoom() + 1);
                    e.stopPropagation();
                    e.preventDefault();
                });

                // Zoom Out
                const zoomOutButton = document.createElement('button');
                const zoomOutButtonIcon = document.createElement('i');
                zoomOutButtonIcon.classList.add('icon');
                zoomOutButtonIcon.classList.add('icon-minus');
                zoomOutButtonIcon.classList.add('fs-icon-xs');
                zoomOutButton.append(zoomOutButtonIcon);
                zoomOutButton.classList.add('custom-map-zoom-out-button');
                zoomOutButton.addEventListener('click', (e) => {
                    map.setZoom(map.getZoom() - 1);
                    e.stopPropagation();
                    e.preventDefault();
                });

                // divider
                var divider = document.createElement('div');
                divider.classList.add('zoom-divider');

                // Zoom Wrapper
                var zoomButton = document.createElement('div');
                zoomButton.append(zoomInButton);
                zoomButton.append(divider);
                zoomButton.append(zoomOutButton);
                zoomButton.classList.add('custom-map-zoom-wrapper');

                map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(zoomButton);

                const locationButton = document.createElement('button');
                const locationButtonIcon = document.createElement('i');
                locationButtonIcon.classList.add('icon');
                locationButtonIcon.classList.add('icon-crosshair');
                locationButtonIcon.classList.add('fs-icon-xs');
                locationButton.append(locationButtonIcon);

                locationButton.classList.add('custom-map-control-button');
                map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(locationButton);
                locationButton.addEventListener('click', () => {
                    // Try HTML5 geolocation.
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                const pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };

                                map.setCenter(pos);
                                // eslint-disable-next-line no-new
                                new google.maps.Marker({
                                    position: pos,
                                    map: map,
                                    icon: $('.map-canvas').data('marker-icon-pin')
                                });
                            },
                            () => {}
                        );
                    } else {
                        // Browser doesn't support Geolocation
                    }
                });
            }
        }
    );
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {Object} params - parameters to be used in the url
 */
function fillModalElement(selectedValueUrl, params) {
    $('#shippingModal').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        data: params,
        dataType: 'json',
        /**
         * @param {*} data Response from server
         * Success response.
         */
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            $("script[src*='maps.googleapis.com']").remove();

            $.getScript(data.googleMapsApi, function () {
                getModalHtmlElement();
                var shippingModal = $('#shippingModal');
                var modalBody = shippingModal.find('.modal-body');
                modalBody.empty();
                modalBody.html(parsedHtml.body);
                shippingModal.find('.modal-header .modal-header-title').empty().append(parsedHtml.header);
                shippingModal.find('.modal-header .close .sr-only').text(data.closeButtonText);
                shippingModal.find('.enter-message').text(data.enterDialogMessage);
                shippingModal.modal('show');
                window.dataLayer.push({
                    event: DATA_LAYER_CONSTANTS.DELIVERY_SCREEN_EVENT,
                    delivery_method_picker_screen: DATA_LAYER_CONSTANTS.PICKUP_STORES,
                    zipcode: data.preferredAddress.address.postalCode,
                    shipping_tier: DATA_LAYER_CONSTANTS.STORE_COLLECTION
                });
                maps();
                observeStores();
            });

            $.spinner().stop();
        },
        /**
         * Error Handler
         */
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * set store availability based on slot result
 * @param {string} parentElemSelector - target store parent element
 * @param {*} isSlotAvailable - Slot result
 * @param {*} storeID - Store ID
 * @param {*} observer - IntersectionObserver instance
 */
function setStoreStatusBasedOnSlot(parentElemSelector, isSlotAvailable, storeID, observer) {
    const $storeItem = $(parentElemSelector).find(`.store-info-wrapper[data-store-id=${storeID}]`);
    const storeStatus = $storeItem.data('store-status');

    // show store CTA once availability is fetched
    const $storeCTA = $storeItem.find('.store-cta');
    $storeCTA.removeClass('d-none');

    if (storeStatus === 'open' && isSlotAvailable) {
        // remove closed class and hide unavailable cta
        $storeItem.removeClass('closed');
        $storeCTA.find('.store-unavailable').addClass('d-none');
    } else {
        // add closed class and hide available cta
        $storeItem.addClass('closed');
        $storeCTA.find('.store-available').addClass('d-none');
    }

    // set slot result in attribute
    $storeItem.attr('data-slot-result', isSlotAvailable);
    $storeItem.attr('data-fetched', true);
    // remove observer
    if (observer) {
        observer.unobserve($storeItem[0]);
    }
}

/**
 * Check slot availability for stores based on ID
 * @param {string} parentElemSelector - target store parent element
 * @param {*} stores - target element stores list
 * @param {*} observer - IntersectionObserver instance
 */
function handleSlotAvailability(parentElemSelector, stores, observer) {
    const $parentElem = $(parentElemSelector);
    if (stores.length === 0) {
        return;
    }
    const storesToFetch = [];

    stores.forEach((store) => {
        if (store.facilityID && store.facilityID !== 'null') {
            storesToFetch.push(store);
        } else {
            // If facility ID is null directly set slot availability as false
            setStoreStatusBasedOnSlot(parentElemSelector, false, store.ID, observer);
        }
    });

    if (storesToFetch.length === 0) {
        return;
    }

    if (!observer || !storeAvailabilityPollingInitiated) {
        getSlotAvailability(parentElemSelector, storesToFetch);
    } else {
        storesToFetch.forEach((store) => {
            if (!$parentElem.find(`.store-info-wrapper[data-store-id=${store.ID}]`).data('fetched')) {
                storesToFetchAvailability.add(store);
            }
        });
    }
}

/**
 * Check slot availability for stores based on ID
 * @param {string} parentElemSelector - target store parent element
 * @param {Array} storesToFetch - target element stores list
 * @param {*} observer - IntersectionObserver instance
 */
function getSlotAvailability(parentElemSelector, storesToFetch, observer) {
    const slotAvailabilityUrl = $('.stores-wrapper .list-group').data('slot-availability-url');
    const $parentElem = $(parentElemSelector);
    $parentElem.addClass('loading').spinner().start();
    const getSlotAvailabilityPromise = () => {
        return new Promise(function (resolve, reject) {
            $.ajax({
                url: slotAvailabilityUrl,
                method: 'GET',
                data: {
                    stores: JSON.stringify(storesToFetch) // Send storesToFetch instead of stores
                },
                /**
                 * Success Handler
                 * @param {*} data Response from server
                 */
                success: function (data) {
                    if (data.results) {
                        data.results.forEach((availabilityData) => {
                            setStoreStatusBasedOnSlot(parentElemSelector, availabilityData.available, availabilityData.storeID, observer);
                        });
                        resolve();
                    }
                },
                /**
                 * Error Handler
                 */
                error: function () {
                    reject();
                }
            });
        });
    };

    const minSpinnerDelay = () => {
        return new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, 500);
        });
    };

    Promise.all([getSlotAvailabilityPromise(), minSpinnerDelay()]).finally(function () {
        renderLocationMarkers();
        $parentElem.spinner().stop();
        $parentElem.removeClass('loading');
    });
}

/**
 * observe stores list and fetch slot availability based on intersection
 */
function observeStores() {
    const parentElemSelector = '#stores-list';
    const $parentElem = $(parentElemSelector);
    const thresholdValue = 1;

    const options = {
        root: $parentElem[0],
        rootMargin: '300px',
        threshold: thresholdValue
    };

    const handleIntersect = (entries, observer) => {
        const stores = [];

        entries.forEach((entry) => {
            // If the availability is already fetched, skip
            if (entry.target.dataset.fetched !== 'true') {
                let storeID = entry.target.dataset.storeId;
                let facilityID = entry.target.dataset.facilityId;

                stores.push({
                    ID: storeID,
                    facilityID: facilityID
                });
            }
        });

        handleSlotAvailability(parentElemSelector, stores, observer);
    };

    const observer = new IntersectionObserver(handleIntersect, options);
    const $stores = $parentElem.find('.store-info-wrapper');

    $stores.each((index, store) => {
        // start observing
        observer.observe(store);
    });
}

/**
 * Check slot availability for stores on map marker click
 * @param {*} $storeInfoWindow - Store Info window on map
 */
function checkSlotFromMap($storeInfoWindow) {
    const $storeInfoWrapper = $storeInfoWindow.find('.store-info-wrapper');
    const storeID = $storeInfoWrapper.data('store-id');
    const facilityID = $storeInfoWrapper.data('facility-id');

    // Store info item from list
    const $storeItemFromList = $(`#stores-list .store-info-wrapper[data-store-id=${storeID}]`);
    const slotResult = $storeItemFromList.data('slot-result');

    // Check if slot result already fetched for store in list
    if (slotResult !== undefined) {
        // Set slot result on store in map
        setStoreStatusBasedOnSlot($storeInfoWindow, slotResult, storeID);
    } else {
        handleSlotAvailability($storeInfoWindow, [{ ID: storeID, facilityID }]);
    }
}

/**
 * Render marker icons on map
 * @param {*} mapLocations - mapLocations
 * @param {*} bounds - bounds
 */
function renderMapMarker(mapLocations, bounds) {
    Object.keys(mapLocations).forEach(function (key) {
        const item = mapLocations[key];
        const storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
        const $storeItem = $('#stores-list').find(`.store-info-wrapper[data-store-id=${item.id}]`);
        const slotStatus = $storeItem.data('slot-result');

        const unavailableSlot = slotStatus === undefined || !slotStatus;
        const markerIcon = item.status !== 'open' || unavailableSlot ? item.mapMarkerIconDisabled : item.mapMarkerIcon;
        const marker = new google.maps.Marker({
            position: storeLocation,
            map: map,
            title: item.name,
            icon: $('.map-canvas').data(markerIcon),
            zIndex: 1051
        });

        if (item.status === 'open') {
            // Info Window
            const infoWindowParsedHtml = parseHtml(item.infoWindowHtml);

            google.maps.event.addListener(marker, 'click', function () {
                const storeInfoWindow = $('.store-info-window');
                storeInfoWindow.empty();
                getInfoWindowHtmlElement();
                const modalContent = $('.info-window-content');
                modalContent.html(infoWindowParsedHtml.body);
                checkSlotFromMap(storeInfoWindow);
                storeInfoWindow.addClass('show');
            });
        }
        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);

        markers.push(marker);
    });
}

module.exports = {
    showStoreSelector: () => {
        const prepareModal = (url, params) => {
            $.spinner().start();
            ajaxCallTriggered = false;
            fillModalElement(url, params);
            markers = [];
        };

        $('body').on('click', '.pick-up-in-store', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).data('href');
            prepareModal(appendSelectedAddress(selectedValueUrl));
        });

        $('body').on('storeSelector:refresh', (e, data) => {
            prepareModal(data.url, data.params);
        });
    },
    handleMobileShowMap: () => {
        $('body').on('click', '.shipping-instore a.btn', function () {
            if ($('.results-wrapper ').hasClass('d-none')) {
                $(this).addClass('btn-full-width');
                $(this).css('bottom', '1rem');
                $('.results-wrapper ').removeClass('d-none');
                $('.map-wrapper').addClass('d-none');
                $('.show-list').addClass('d-none');
                $('.hide-list').removeClass('d-none');
            } else {
                $(this).removeClass('btn-full-width');
                $(this).css('bottom', '2rem');
                $('.results-wrapper ').addClass('d-none');
                $('.map-wrapper').removeClass('d-none');
                $('.hide-list').addClass('d-none');
                $('.show-list').removeClass('d-none');
            }
        });
    },
    handleAddressInputLabel: () => {
        $('body').on('input', 'input#address-search-input', function () {
            var inputValue = $(this).val();
            $('.form-control-label.form-control-label-top').toggleClass('d-none', inputValue.trim() === '');
        });
    },
    handleClearGetStoreAvailabilityInterval: () => {
        $('body').on('storeAvailability:clearInterval', () => {
            clearInterval(storeAvailabilityPollingIntervalId);
            storeAvailabilityPollingInitiated = false;
        });
    },
    handleLoadMoreStoresList: () => {
        document.addEventListener(
            'scroll',
            debounce(function (event) {
                if (!storeAvailabilityPollingInitiated) {
                    handleStoresAvailabilityPolling();
                }

                storeAvailabilityPollingInitiated = true;

                if (event.target.id === 'stores-list' && !ajaxCallTriggered) {
                    if ($(event.target).scrollTop() + $(event.target).innerHeight() + 1 >= $(event.target)[0].scrollHeight) {
                        var selectedValueUrl = $('.load-stores-url').data('load-stores-url');

                        $.ajax({
                            url: appendSelectedAddress(selectedValueUrl),
                            method: 'GET',
                            dataType: 'json',
                            /**
                             * @param {*} data Response from server
                             * Success response.
                             */
                            success: function (data) {
                                // Deletes all markers in the array by removing references to them.
                                for (let i = 0; i < markers.length; i++) {
                                    markers[i].setMap(null);
                                }
                                markers = [];
                                var mapLocations = JSON.parse(data.stores.locations);
                                var bounds = new google.maps.LatLngBounds();
                                renderMapMarker(mapLocations, bounds);
                                getStoresListHtmlElement(data.renderedTemplate);
                                observeStores();
                                ajaxCallTriggered = true;
                            },
                            /**
                             * Error Handler
                             */
                            error: function () {}
                        });
                    }
                }
            }, SCROLL_DEBOUNCE),
            true
        );
    },
    handleStoreInfoClose: () => {
        $('body').on('click', '.store-info-window .close', function (e) {
            e.preventDefault();
            $('.store-info-window').empty();
        });
        $(document).on('click', function (event) {
            var $infoWindowContent = $('#infoWindowContent');
            var $eventTarget = $(event.target);
            var $storeInfoWindow = $('.store-info-window');

            if (
                !$infoWindowContent.is(event.target) &&
                !$infoWindowContent.has(event.target).length &&
                !$eventTarget.closest('#infoWindowContent').length &&
                !$storeInfoWindow.hasClass('show') &&
                (!$eventTarget.is('img') || !$eventTarget.attr('src').includes('https://maps.gstatic.com/mapfiles/'))
            ) {
                $storeInfoWindow.empty();
            } else {
                $storeInfoWindow.removeClass('show');
            }
        });
    }
};

/**
 * Chunks storeIds into smaller arrays of size 10.
 * @function
 * @name chunkStoreIds
 * @param {number[]} ids - The array to be chunked.
 * @returns {[][]} - Array of storeId chunks.
 */
const chunkStoreIds = (ids) => {
    if (ids.length === 0) {
        return [];
    }
    const storeIdsSubset = ids.slice(0, MAX_HTTP_CLIENT_REQUESTS);
    return [storeIdsSubset, ...chunkStoreIds(ids.slice(MAX_HTTP_CLIENT_REQUESTS))];
};

/**
 * Polls for store availability on scroll at a 1 second interval.
 * @function
 * @name handleStoresAvailabilityOnScroll
 * @returns {void}
 */
function handleStoresAvailabilityPolling() {
    const parentElemSelector = '#stores-list';
    storeAvailabilityPollingIntervalId = setInterval(() => {
        $(parentElemSelector).spinner().stop();
        const storesToFetchAvailabilityArray = [...storesToFetchAvailability];
        storesToFetchAvailability = new Set([]);
        if (storesToFetchAvailabilityArray.length) {
            const getAvailabilitiesDelayed = (stores, index) => {
                setTimeout(getSlotAvailability(parentElemSelector, stores), STORE_AVAILABILITY_POLLING_INTERVAL * index);
            };

            chunkStoreIds(storesToFetchAvailabilityArray).forEach((storesSubset, index) => getAvailabilitiesDelayed(storesSubset, index));
        }
    }, STORE_AVAILABILITY_POLLING_INTERVAL);
}
