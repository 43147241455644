'use strict';

var base = require('base/components/footer');

/**
 * Module for handling country selection functionality.
 * @module countrySelector
 */

module.exports = {
    baseFeatures: base,
    languageSelector: () => {
        $(document).ready(function () {
            setTimeout(() => {
                $('.language-selector').select2({
                    dropdownParent: $('.language-selector').parent(),
                    minimumResultsForSearch: -1,
                    templateSelection: (selectedOption) => {
                        return selectedOption.text;
                    },
                    templateResult: (option) => {
                        if (option.selected) {
                            return null;
                        }
                        return option.text;
                    }
                });
            }, 10);
        });
        $('.language-selector').on('change', function (e) {
            e.preventDefault();
            var $this = $(this).find('option:selected');
            var action = $('.page').data('action');
            var localeCode = $this.data('locale');
            var localeCurrencyCode = $this.data('currencycode');
            var queryString = $('.page').data('querystring');
            var url = $('.language-selector').data('url');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: action
                },
                /**
                 * Handles the success response of the country selection AJAX call.
                 * @param {Object} response - The response object from the AJAX call.
                 */
                success: function (response) {
                    $.spinner().stop();
                    window.location.href = response.redirectUrl;
                }
            });
        });
    }
};
