const debounce = require('lodash/debounce');

const initBreadcrumbs = () => {
    const breadcrumb = $('.breadcrumb');

    if (breadcrumb.length === 0) {
        return;
    }

    const screenWidth = $(window).width();
    let breadcrumbWidth = breadcrumb.width();
    const items = $('.breadcrumb__item');
    const itemsLength = items.length;
    let i = 1;
    while (breadcrumbWidth > screenWidth && i < itemsLength) {
        items.eq(i).addClass('ellipsis');
        items.eq(i).find('a').hide();
        breadcrumbWidth = breadcrumb.width();
        i++;
    }
    breadcrumb.removeClass('hidden');
};

module.exports = {
    initBreadcrumbs: () => {
        initBreadcrumbs();
        $(window).on('resize', debounce(initBreadcrumbs, 100));
    }
};
