'use strict';

const { tippyTooltip } = require('../tooltipTippy');
const { updateQueryParam } = require('../utils');
const {
    updateProductTilesWithCartData,
    updateAddToCartButtons,
    getReplacementProducts,
    getProductTilesAvailabilityInformation,
    updateProductsWishlistStatus,
    getProductPosition
} = require('./productUtils');
var wishlistPage = $('.wishlist-landing-page');
const { methods } = require('wishlist_carrefour/wishlist/wishlist');

const hideReplacementProducts = (e) => {
    const $replacementProducts = $(e.currentTarget).closest('.replacements');
    const $replacementOverlay = $replacementProducts.siblings('.replacements-overlay');

    if ($replacementProducts.length) {
        $replacementProducts.removeClass('show');
        $replacementProducts.empty();
    }

    if ($replacementOverlay.length) {
        $replacementOverlay.removeClass('show');
    }
};

const removeProductFromcart = ($input) => {
    const removeProductLineItemUrl = $input.attr('data-remove');
    const uuid = $input.attr('data-uuid');
    const pid = $input.attr('data-pid');
    const $productTile = $input.closest('.product-tile');

    var itemPosition = getProductPosition($productTile, true);

    if (window.updateQuantityTrigger && window.updateQuantityTrigger[pid]) {
        clearTimeout(window.updateQuantityTrigger[pid]);
    }

    const $body = $('body');
    $body.trigger('cart:beforeUpdate');

    $.ajax({
        url: removeProductLineItemUrl,
        method: 'GET',
        data: {
            uuid: uuid,
            pid: pid,
            itemListId: itemPosition.itemListId,
            itemListName: itemPosition.itemListName
        },
        /**
         * Success Handler
         * @param {*} data Response from server
         */
        success: function (data) {
            updateProductTilesWithCartData(data.basket, 'js-product-tile', 'product');
            $body.trigger('cart:update', data.basket);
            $body.trigger('product:afterRemoveFromCart', data);
        }
    });
};

/**
 * Enable Add to cart in product tile, without quickview
 */
const addToCart = () => {
    $(document).on('click', '.js-product-tile a.add-to-cart', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $body = $('body');
        var addToCartUrl = $('#add-to-cart-url').val();
        var $productTile = $this.closest('.js-product-tile');
        var pid = $productTile.closest('.product').attr('data-pid');
        var itemPosition = getProductPosition($productTile, true);

        const isCartPage = $('.cart-page').length !== 0;
        const isReplacementModalOpen = $('.replacement-products-modal.show').length !== 0;
        hideReplacementProducts(e);

        $body.trigger('product:beforeAddToCart', this);

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        var form = {
            pid: pid,
            pidsObj: [],
            childProducts: [],
            quantity: 1,
            itemListId: itemPosition.itemListId,
            itemListName: itemPosition.itemListName,
            useLightModel: !isCartPage,
            isReplacementProduct: isReplacementModalOpen && isCartPage
        };

        if (window.updateQuantityTrigger && window.updateQuantityTrigger[pid]) {
            clearTimeout(window.updateQuantityTrigger[pid]);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                /**
                 * Success Handler
                 * @param {*} data Response from server
                 */
                success: function (data) {
                    const $miniCart = $('.minicart');
                    if (data.error && data.showSelectShippingMethod) {
                        $miniCart.trigger('count:update');
                        $body.trigger('shippingMethod:select', { trigger: 'cart', includeAddress: true, productId: pid });
                    } else {
                        $miniCart.trigger('count:update', data);
                        if (data.isReplacementProduct) {
                            $('.cf-list-accordion').html(data.renderedProductCards);
                            $body.trigger('product:afterRemoveFromCart', { basket: data.cart });
                        }

                        $body.trigger('product:afterAddToCart', data);
                        if (wishlistPage.length) {
                            var productListItems = $('.js-wishlist-product');
                            var basketItems = $(data.cart.items);
                            methods.pageLoadCheck(productListItems, basketItems);
                        }
                    }
                }
            });
        }
    });
};

const updateQuantityWithMinusAndPlus = () => {
    window.updateQuantityTrigger = {};
    $('body').on('click', '.js-product-tile .minus-btn, .js-product-tile .plus-btn', function () {
        var $btn = $(this);
        var $productTile = $btn.closest('.js-product-tile');
        var delta = $btn.hasClass('plus-btn') ? 1 : -1;
        var $input = $btn.parent().find('input.quantity');
        var $quantityForm = $btn.parent();
        var qty = parseInt($input.val(), 10);
        var preSelectQty = parseInt($input.attr('data-pre-select-qty'), 10);
        var newQty = qty + delta;
        var maxQty = parseInt($input.attr('data-max-qty'), 10);
        var pid = $productTile.parent().attr('data-pid');
        var itemPosition = getProductPosition($productTile, true);

        var updateQuantityUrl = $input.attr('data-action');
        var updatedQuantityUrl = updateQueryParam(updateQuantityUrl, 'action', delta < 0 ? 'remove' : 'add');
        updatedQuantityUrl = updateQueryParam(updatedQuantityUrl, 'itemListId', itemPosition.itemListId);
        updatedQuantityUrl = updateQueryParam(updatedQuantityUrl, 'itemListName', itemPosition.itemListName);
        updatedQuantityUrl = updateQueryParam(updatedQuantityUrl, 'selectedQty', Math.abs(preSelectQty - newQty));
        $input.data('action', updatedQuantityUrl);

        if (newQty >= maxQty) {
            $productTile.addClass('has-max-qty');
        } else {
            $productTile.removeClass('has-max-qty');
        }

        if (window.updateQuantityTrigger[pid]) {
            clearTimeout(window.updateQuantityTrigger[pid]);
        }

        if (newQty > 0) {
            $input.val(newQty);
            window.updateQuantityTrigger[pid] = setTimeout(() => {
                $quantityForm.addClass('disabled');
                $input.trigger('change');
            }, 300);
        } else {
            // Remove item from Cart
            removeProductFromcart($input);
        }
    });
};

const handleCartUpdate = () => {
    var $body = $('body');
    $body.on('cart:update', function (e, data) {
        updateProductTilesWithCartData(data, 'js-product-tile', 'product');
    });

    $body.on('product:afterAddToCart', function (e, data) {
        updateProductTilesWithCartData(data.cart, 'js-product-tile', 'product');
    });
};

const handleSearchFeatures = () => {
    var $body = $('body');

    // Select the node that will be observed for mutations
    const targetNode = $('.product-grid')[0];

    if (!targetNode) {
        // Ignore if out of search page
        return;
    }

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: false };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(() => {
        $body.trigger('tile:needUpdate');
    });

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    $body.on('search:showMore search:sort search:filter search:showPrevious search:init', function () {
        window.watchTiles = true;
    });

    $body.on('tile:needUpdate', function () {
        if (window.watchTiles) {
            updateAddToCartButtons();
            getProductTilesAvailabilityInformation();
            updateProductsWishlistStatus();
            tippyTooltip();
        }
    });

    $body.on('tile:afterAddToCartBtnUpdate', function () {
        window.watchTiles = false;
    });
};

const closeReplacementProducts = () => {
    $(document).on('click', '.replacements .close-replacements', (e) => {
        e.stopPropagation();
        hideReplacementProducts(e);
    });
};

const showReplacementProductsModal = () => {
    $(document).on('click', '.product-tile .replace-product, .cart-product-card .replace-product', (e) => {
        getReplacementProducts(e, 'product');
    });
};

module.exports = {
    addToCart: addToCart,
    updateQuantityWithMinusAndPlus: updateQuantityWithMinusAndPlus,
    handleCartUpdate: handleCartUpdate,
    handleSearchFeatures: handleSearchFeatures,
    getProductTilesAvailabilityInformation: getProductTilesAvailabilityInformation,
    showReplacementProductsModal: showReplacementProductsModal,
    closeReplacementProducts: closeReplacementProducts,
    updateAddToCartButtons: updateAddToCartButtons,
    updateProductsWishlistStatus: updateProductsWishlistStatus
};
