'use strict';

const { loadGigya } = require('../gigya/gigyaUtils');

module.exports = function () {
    const $myAccount = $('.myaccount');
    const $closeIcon = $myAccount.find('.icon-x');
    const $accountPopover = $('.myaccount .popover');
    const $accountDropdownWrapper = $('.myaccount .popover .account-dropdown-wrapper');
    const $accountUserIcon = $('.myaccount a.account-show');
    const $actionUrl = $myAccount.data('action-url');
    const $body = $('body');
    const $overlay = $('.tablet-navbar-overlay');
    const $gtmNavMyAccountCta = $('.js-gtm-nav-myaccount-cta');

    /**
     * Toggles a popover overlay for mobile and tablet devices.
     *
     * @param {boolean} enabled - Whether to enable or disable the popover overlay.
     * @returns {void}
     */
    function togglePopoverOverlay(enabled) {
        if (app.isMobile() || (app.isTablet() && !app.checkBreakPoint('lg'))) {
            const action = enabled ? 'addClass' : 'removeClass';
            $body[action]('no-scroll');
            $overlay[action]('show');
        }
    }

    $accountUserIcon.on('click', function (e) {
        e.preventDefault();
        $gtmNavMyAccountCta.first().trigger('click');
        e.stopPropagation();

        $.get($actionUrl)
            .done(function (data) {
                $accountDropdownWrapper.empty().append(data);
                $accountPopover.addClass('show');
                togglePopoverOverlay(true);
            })
            .fail(function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            });
    });

    $closeIcon.on('click', function () {
        $accountPopover.removeClass('show');
        togglePopoverOverlay(false);
    });

    $(document).on('click', function (e) {
        if ($accountPopover.length === 0) {
            return;
        }
        const isClickedInsideDropDown = $accountPopover.get(0).contains(e.target);
        if (!isClickedInsideDropDown) {
            $accountPopover.removeClass('show');
            togglePopoverOverlay(false);
        }
    });

    $body.on('click', '.js-gigya-logout', function (e) {
        e.preventDefault();
        const $targetElem = $(this).closest('a');
        $.spinner().start();

        const gigyaLogout = () => {
            const logoutRedirect = (response) => {
                // eslint-disable-next-line eqeqeq
                if (response.errorCode == 0) {
                    // Redirect to the href attribute of the clicked element
                    const redirectUrl = $targetElem.attr('href');
                    if (redirectUrl) {
                        window.location.href = redirectUrl;
                    } else {
                        // Provide a default redirect URL if href attribute is not set
                        window.location.href = '/logout';
                    }
                }
                $.spinner().stop();
            };

            // eslint-disable-next-line no-undef
            gigya.accounts.logout({ callback: logoutRedirect });
        };

        loadGigya($targetElem[0], gigyaLogout);
    });

    $gtmNavMyAccountCta
        .on('click', function () {
            const gtmNavCta = $(this).attr('data-gtm-nav-cta');

            if (window.dataLayer && gtmNavCta) {
                window.dataLayer.push({
                    event: 'top_nav',
                    top_nav_cta: gtmNavCta
                });
            }
        })
        .on('click', '.popover *', function (e) {
            // Do not send the GTM event if a click
            // occured from inside the myaccount dropdown
            e.stopPropagation();
        });
};
