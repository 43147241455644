'use strict';

const { getProductsAvailability, showReplacementProductsModal } = require('app_carrefour/product/productUtils');

/**
 * Process pending add to cart product after shipping method selection
 * @returns {void}
 */
const processPendingProduct = () => {
    const pendingProductId = window.sessionStorage.getItem('pendingCartProduct');

    if (!pendingProductId) {
        return;
    }
    getProductsAvailability([pendingProductId], function (data) {
        if (data.results) {
            const availabilityData = data.results[0];
            if (availabilityData.ats <= 0) {
                showReplacementProductsModal(pendingProductId);
            } else {
                const $addToCartCta = $(`[data-pid="${pendingProductId}"].js-add-to-cart`)[0];
                if ($addToCartCta) {
                    $addToCartCta.click();
                }
            }
        }
    });

    window.sessionStorage.removeItem('pendingCartProduct');
};

module.exports = {
    processPendingProduct
};
