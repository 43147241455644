'use strict';

const $miniCart = $('.minicart');
var productListItems = $('.accordion-list .cart-product-card');
var wishlistAddAllToCartBtn = $('.js-wishlist-add-all-to-cart');
var wishlistPage = $('.wishlist-landing-page');
var basketResponse = $('.basket-response');
var basket;
var $body = $('body');

/**
 * Enable / Disable add all products from wishlist
 * Check performed on page load
 * @param {Array} wishlistItems - array of wishlist items
 * @param {Array} basketItems - array of basket items
 */
function pageLoadCheck(wishlistItems, basketItems) {
    if (wishlistPage.length) {
        var wishlistItemsArray = [];
        var basketItemsArray = [];
        $.each(wishlistItems, function (i, itemsInWishlist) {
            var pid = itemsInWishlist.dataset.pid;
            wishlistItemsArray.push(pid);
        });
        $.each(basketItems, function (i, itemsInBasket) {
            var basketItemsPid = itemsInBasket.id;
            basketItemsArray.push(basketItemsPid);
        });
        var sortedArr1 = wishlistItemsArray.slice().sort();
        var sortedArr2 = basketItemsArray.slice().sort();

        // Check if the values at corresponding positions are the same
        if (wishlistItems.length === basketItems.length) {
            for (var i = 0; i < sortedArr1.length; i++) {
                // eslint-disable-next-line max-depth
                if (sortedArr1[i] !== sortedArr2[i]) {
                    wishlistAddAllToCartBtn.removeAttr('disabled', true);
                    wishlistAddAllToCartBtn.removeClass('added-all-to-cart');
                } else {
                    wishlistAddAllToCartBtn.attr('disabled', true);
                    wishlistAddAllToCartBtn.addClass('added-all-to-cart');
                }
            }
        }
    }
}

$(document).ready(function () {
    if (basketResponse.length) {
        basket = JSON.parse($('.basket-response').val());
    }
    pageLoadCheck(productListItems, basket);
});

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${
        '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>'
    }${message}</div>`;

    $('.error-messaging').append(errorHtml);
}

module.exports = {
    methods: {
        pageLoadCheck: pageLoadCheck
    },
    /**
     * Remove Item from wishlist
     */
    removeFromWishlist: function () {
        $body.on('click', '.js-remove-product-wishlist', function (e) {
            e.preventDefault();
            let url = $(this).data('url');
            let pid = $(this).data('pid');

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                /**
                 * Handle success remove call
                 * @param {*} data A JSOn response object with flag 'listIsEmpty'
                 */
                success: function (data) {
                    if (data.listIsEmpty) {
                        $('.wishlist-cards-container').remove();
                        $('.empty-wishlist-wrapper').removeClass('d-none');
                    }
                    let $cardWrapper = $(`.js-wishlist-product[data-pid=${pid}]`).parent();
                    let $groupList = $cardWrapper.closest('.accordion-list');
                    $cardWrapper.remove();

                    let groupItemsCount = $groupList.find('.js-wishlist-product').length;
                    let $groupWrapper = $groupList.closest('.accordion-item');
                    if (groupItemsCount > 0) {
                        let $countElem = $groupWrapper.find('.accordion-header .count');
                        $countElem.text(groupItemsCount);
                    } else {
                        $groupWrapper.remove();
                    }
                    $.spinner().stop();
                }
            });
        });
    },
    /**
     * Add all wishlist items to cart
     */
    addAllToCart: function () {
        $body.on('click', '.js-wishlist-add-all-to-cart', function (event) {
            $body.trigger('cart:beforeUpdate');
            event.preventDefault();
            var url = $(this).data('url');
            $.ajax({
                url: url,
                type: 'post',
                /**
                 * Response handler
                 * @param {*} response A JSON response object with error flag, and new cart data
                 */
                success: function (response) {
                    if (!response.error) {
                        $miniCart.trigger('count:update', response);
                        $body.trigger('product:afterAddToCart', response);
                        wishlistAddAllToCartBtn.attr('disabled', true);
                        $body.trigger('minicart:opensidenav');
                        $body.trigger('minicart:loadMiniCartPreview');
                    } else if (response.error && response.showSelectShippingMethod) {
                        $body.trigger('shippingMethod:select', { trigger: 'cart', includeAddress: true });
                    } else {
                        createErrorNotification(response.errorMessage);
                        $('.error-messaging').addClass('wishlist-addallto-cart');
                    }
                },
                /**
                 * End mini cart loading
                 * @param {*} response A JSON response object
                 */
                complete: function () {
                    $.spinner().stop();
                    $body.trigger('count:update');
                }
            });
        });
    }
};
