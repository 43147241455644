const lodash = require('lodash');

let goback = lodash.debounce(
    function () {
        var goBackUrl = $('.js-back-btn').data('back-url');
        var homeUrl = $('.js-back-btn').data('home-url');
        if (goBackUrl) {
            window.location.href = goBackUrl;
        } else {
            window.location.href = homeUrl;
        }
    },
    1000,
    { leading: true, trailing: true }
);

$('.js-back-btn').on('click', function (e) {
    e.preventDefault();
    goback();
});
