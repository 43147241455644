/**
 * Update the query param in the url
 * @param {string} url - url to update
 * @param {string} key - key to update
 * @param {string} value - value to update
 * @returns {string} - updated url
 */
function updateQueryParam(url, key, value) {
    if (!value) return url;
    if (url.includes(key + '=')) {
        let prefix = url.substring(0, url.indexOf(key));
        let suffix = url.substring(url.indexOf(key));
        suffix = suffix.substring(suffix.indexOf('=') + 1);
        suffix = suffix.indexOf('&') > -1 ? suffix.substring(suffix.indexOf('&')) : '';
        return prefix + key + '=' + value + suffix;
    }
    if (url.includes('?')) {
        return url + '&' + key + '=' + value;
    }
    return url + '?' + key + '=' + value;
}

module.exports = {
    updateQueryParam: updateQueryParam
};
