'use strict';

const checkPendingOrder = () => {
    if (!window.hasPendingOrder) {
        return;
    }

    const checkPendingOrderURL = $('#check-pending-order').val();
    const isCartPage = $('#maincontent > .cart-empty, #maincontent > .cart-page').length > 0;

    if (isCartPage) {
        $.spinner().start();
    }

    setTimeout(() => {
        $.ajax({
            url: checkPendingOrderURL,
            method: 'POST',
            dataType: 'json',
            /**
             * On Success Handler
             * @param {*} data Data about cancel and merge process
             */
            success: function (data) {
                if (data.isRecovered) {
                    window.location.reload();
                } else {
                    $.spinner().stop();
                }
            },
            error: () => {
                $.spinner().stop();
            }
        });
    }, 500);
};

module.exports = {
    handleErrorCutOffTimeAlert: () => {
        var $body = $('body');

        $body.on('click', '.cut-off-time-alert .close-icon', function () {
            $('.error-messaging.cut-off-time-alert').addClass('d-none');
        });

        $body.on('click', function (e) {
            if (!$(e.target).closest('.error-messaging.cut-off-time-alert').length) {
                $('.error-messaging.cut-off-time-alert').addClass('d-none');
            }
        });
    },
    checkPendingOrder: checkPendingOrder
};
