'use strict';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map(function (key) {
                return key + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

    return newUrl;
}

module.exports = {
    /**
     * Generates the modal window on the first call.
     * @param {string} modalClass - Custom class for different modal viewport
     *
     */
    getModalHtmlElement: (modalClass = 'custom-shipping-modal') => {
        var $shippingModal = $('#shippingModal');

        if ($shippingModal.length !== 0) {
            $shippingModal.find('.modal-header .arrow-left').removeClass('d-none');
            $shippingModal
                .removeClass()
                .addClass(`modal fade show ${modalClass}`)
                .removeAttr('data-refresh-url data-refresh-selector data-refresh-event-post data-refresh-event-override');
            return;
        }
        var htmlString =
            '<!-- Modal -->' +
            `<div class="modal fade ${modalClass}" id="shippingModal" role="dialog">` +
            '<span class="enter-message sr-only" ></span>' +
            '<div class="modal-dialog">' +
            '<!-- Modal content-->' +
            '<div class="modal-content">' +
            '<div class="modal-header pb-0">' +
            '    <div class="modal-header-title"></div>' +
            '    <button type="button" class="close arrow-left" data-dismiss="modal">' +
            '        <i class="icon icon-arrow-left"></i>' +
            '    </button>' +
            '    <button type="button" class="close close-shipping-modal" data-dismiss="modal">' +
            '        <i class="icon icon-x"></i>' +
            '    </button>' +
            '</div>' +
            '<div class="modal-body"></div>' +
            '</div>' +
            '</div>' +
            '</div>';
        $('body').append(htmlString);
    },

    /**
     * Parse HTML code in Ajax response
     *
     * @param {string} html - Rendered HTML from quickview template
     * @return {QuickViewHtml} - QuickView content components
     */
    parseHtml: (html) => {
        var $html = $('<div>').append($.parseHTML(html));

        var body = $html.find('.modal-body-content');
        var header = $html.find('.modal-header-content');

        return { body: body, header: header };
    },
    getInfoWindowHtmlElement: () => {
        var infoWindowModal = $('#infoWindowModal');

        if (infoWindowModal.length !== 0) {
            $('.info-window-content').removeClass().addClass('info-window-content');
            return;
        }
        var htmlString = '<div class="info-window-content" id="infoWindowContent"></div>';
        $('.store-info-window').append(htmlString);
    },
    getStoresListHtmlElement: (htmlString) => {
        var $listWrapper = $('.stores-wrapper .list-group');

        $listWrapper.empty();
        $listWrapper.append(htmlString);
    },
    prepareRefreshEventData: ($target) => {
        const refreshURL = $target.closest('[data-refresh-url]').attr('data-refresh-url');
        const refreshTargetSelector = $target.closest('[data-refresh-selector]').attr('data-refresh-selector');
        const refreshTargetEventPostRefresh = $target.closest('[data-refresh-event-post]').attr('data-refresh-event-post');
        const refreshTargetEvenntOverride = $target.closest('[data-refresh-event-override]').attr('data-refresh-event-override');

        return {
            url: refreshURL,
            selector: refreshTargetSelector,
            eventPostRefresh: refreshTargetEventPostRefresh,
            eventOverride: refreshTargetEvenntOverride
        };
    },
    refreshComponent: (data) => {
        if (data.eventOverride) {
            $('body').trigger(data.eventOverride, { url: data.url, params: data.params, selector: data.selector, eventPostRefresh: data.eventPostRefresh });
        } else {
            const $target = $(data.selector);
            if ($target.length === 0 || !data.url) {
                return;
            }

            let url = data.url;
            var isCheckoutPage = $('#checkout-main').length;
            var $timeSlots = $('.js-select-time-slot');
            if (data.params) {
                url = appendToUrl(url, data.params);
            }

            $.ajax({
                url: url,
                type: 'get',
                /**
                 * Handles the success response of the shipping component AJAX call.
                 * @param {Object} responseHTML - The response object from the AJAX call.
                 */
                success: function (responseHTML) {
                    if (isCheckoutPage) {
                        $('.time-slot-list-item .custom-spinner').addClass('d-none');
                        $('.time-slot-list-item.spinner-active').removeClass('spinner-active');
                        $timeSlots.removeClass('click-disabled');
                    }
                    $target.html(responseHTML);
                    if (data.eventPostRefresh) {
                        $('body').trigger(data.eventPostRefresh);
                    }
                }
            });
        }
    },
    appendSelectedAddress: (url) => {
        var newUrl = url;
        let selectedShippingAddr = $('input[name=selectedShippingAddr]').val();
        if (selectedShippingAddr) {
            newUrl = appendToUrl(newUrl, {
                selectedAddress: selectedShippingAddr
            });
        }
        return newUrl;
    },
    appendToUrl: appendToUrl,
    DATA_LAYER_CONSTANTS: {
        DELIVERY_SCREEN_EVENT: 'delivery_method_screen_view',
        SLOT_SCREEN_EVENT: 'slot_picker_screen_view',
        ZIPCODE: 'zipcode',
        HOME_DELIVERY: 'home delivery',
        CONFIRM_HOME_DELIVERY: 'confirm home delivery',
        CONFIRM_STORE_COLLECTION: 'confirm store collection',
        STORE_COLLECTION: 'store collection',
        HOME_DELIVERY_ID: 'home-delivery',
        CHOOSE_DELIVERY_METHOD: 'choose delivery',
        PICKUP_STORES: 'pickup stores',
        SELECT_SLOT: 'select'
    }
};
