'use strict';

const { getModalHtmlElement, parseHtml, prepareRefreshEventData, refreshComponent, appendSelectedAddress, DATA_LAYER_CONSTANTS } = require('./shippingUtils');

const SEARCH_COOLDOWN = 300;

const removeSuggestions = ($input) => {
    const $suggestionsWrapper = $input.closest('form').find('.address-suggestions-wrapper');
    $suggestionsWrapper.find('li:not(.geolocation)').remove();
    return $suggestionsWrapper;
};

const showQuickZipSuggestions = ($form, quickZipAddresses, selectedFirstAddress) => {
    let $input = $form.find('#address-search-input');
    const $suggestionsWrapper = removeSuggestions($input);

    // Show selected suggestion from google place api in input
    $input.val(selectedFirstAddress);

    quickZipAddresses.forEach((address) => {
        const $address = $('<li>');
        $address.data('quick-zip-address', JSON.stringify(address));
        $address.html(address.formattedAddress);

        $address.appendTo($suggestionsWrapper);
    });
};

/**
 * Highlight a searchTerm within a suggestion string by wrapping it with <mark> tags.
 * Ignores non-alphanumeric characters during matching but retains them in the final output.
 *
 * @param {string} suggestion - The string where the searchTerm will be highlighted.
 * @param {string} searchTerm - The string that will be highlighted in the suggestion.
 *
 * @returns {string} - The suggestion string with the searchTerm highlighted using <mark> tags.
 *
 * @example
 * const highlighted = highlightSearchTerm("Example's String", "Str");
 * // returns: "Example's <mark>Str</mark>ing"
 */
const highlightSearchTerm = (suggestion, searchTerm) => {
    // Remove non-alphanumeric characters and convert to lowercase
    // for non-case-sensitive comparison. This is done by replacing
    // everything that is not alphanumeric (a-z, A-Z, 0-9) with an empty string.
    const normalizedSearchTerm = searchTerm.replace(/[^a-z0-9]/gi, '').toLowerCase();
    const normalizedSuggestion = suggestion.replace(/[^a-z0-9]/gi, '').toLowerCase();

    // Find the start index of the normalized searchTerm in the normalized suggestion.
    // If it’s not present, the original, non-highlighted suggestion is returned.
    const startIndex = normalizedSuggestion.indexOf(normalizedSearchTerm);
    if (startIndex === -1) return suggestion;

    // Identify the end index of the searchTerm in the normalized suggestion by
    // adding its length to the start index.
    const endIndex = startIndex + normalizedSearchTerm.length;

    // originalStartIndex and originalEndIndex will store the start and
    // end index of searchTerm in the original suggestion (with all characters).
    let originalStartIndex = 0;
    let originalEndIndex = 0;

    // j keeps track of the index in the normalized strings.
    let j = 0;

    // Iterate through all characters of the original suggestion.
    for (let i = 0; i < suggestion.length; i++) {
        // Only increase j if the current character is alphanumeric,
        // ensuring consistency with our normalized strings.
        if (/[a-z0-9]/i.test(suggestion[i])) {
            // When j equals startIndex, it means we have found the start
            // of searchTerm in the original string.
            if (j === startIndex) originalStartIndex = i;

            // Similarly, when j equals (endIndex - 1), it means we have found the end
            // of searchTerm in the original string.
            if (j === endIndex - 1) {
                originalEndIndex = i;
                break;
            }
            j++;
        }
    }

    // Construct the highlighted suggestion:
    // - slice(0, originalStartIndex): string before the searchTerm.
    // - <mark>: opening tag for highlighting.
    // - slice(originalStartIndex, originalEndIndex + 1): the searchTerm itself.
    // - </mark>: closing tag for highlighting.
    // - slice(originalEndIndex + 1): string after the searchTerm.
    return suggestion.slice(0, originalStartIndex) + '<mark>' + suggestion.slice(originalStartIndex, originalEndIndex + 1) + '</mark>' + suggestion.slice(originalEndIndex + 1);
};

const transferRefreshEventData = ($source, $target) => {
    if ($source.closest('.modal.standalone').length > 0) {
        // No need to transfer refresh data, the source is a standalone modal, that will be closed.
        return;
    }

    const data = prepareRefreshEventData($source);

    if (data.url) {
        $target.attr('data-refresh-url', data.url);
    }

    if (data.selector) {
        $target.attr('data-refresh-selector', data.selector);
    }

    if (data.eventPostRefresh) {
        $target.attr('data-refresh-event-post', data.eventPostRefresh);
    }

    if (data.eventOverride) {
        $target.attr('data-refresh-event-override', data.eventOverride);
    }
};

const searchAddress = (event) => {
    const $input = $(event.currentTarget);
    const $form = $input.closest('form');
    const $mainWrapper = $form.closest('.shipping-selector-address-search').parent();
    const minLength = $input.attr('data-min-length');
    const query = $input.val();

    $mainWrapper.addClass('searching');

    if (query.length > 0) {
        $form.addClass('typing');
    } else {
        $form.removeClass('typing');
    }

    if (window.searchAddressXHR) {
        window.searchAddressXHR.abort();
    }

    if (window.searchAddressTimeoutID) {
        clearTimeout(window.searchAddressTimeoutID);
    }

    const $suggestionsWrapper = removeSuggestions($input);

    if (query.length < minLength) {
        return;
    }

    const url = $input.attr('data-url');

    const callServer = () => {
        window.searchAddressXHR = $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                q: query
            },
            /**
             * Handles the success response of the search address AJAX call.
             * @param {Object} response - The response object from the AJAX call.
             */
            success: function (response) {
                if (!response.addresses) {
                    return;
                }

                response.addresses.forEach((address) => {
                    const $address = $('<li>');
                    $address.data('place-id', address.place_id);
                    $address.data('formatted-address', address.formattedAddress);
                    $address.html(highlightSearchTerm(address.formattedAddress, query));

                    $address.appendTo($suggestionsWrapper);
                });
            }
        });
    };

    window.searchAddressTimeoutID = setTimeout(callServer, SEARCH_COOLDOWN);
};

const selectAddress = (event, geoLocation) => {
    const $target = $(event.currentTarget);

    let addressData = {
        placeId: $target.data('place-id'),
        geoLocation: geoLocation,
        quickZipAddress: $target.data('quick-zip-address')
    };

    const $wrapper = $target.closest('.address-suggestions-wrapper');
    const $form = $target.closest('form');
    const $contentWrapper = $form.closest('.shipping-selector-address-search').parent();

    const url = $wrapper.attr('data-on-select-url');

    const refreshData = prepareRefreshEventData($target);

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            address: JSON.stringify(addressData)
        },
        /**
         * Handles the success response of the select address AJAX call.
         * @param {Object} response - The response object from the AJAX call.
         */
        success: function (response) {
            if (!response.error) {
                refreshData.params = {
                    selectedAddress: response.selectedAddress
                };
                $('body').trigger('shippingSelector:refresh', refreshData);
                const selectedAddress = JSON.parse(response.selectedAddress);
                window.dataLayer.push({
                    event: DATA_LAYER_CONSTANTS.DELIVERY_SCREEN_EVENT,
                    delivery_method_picker_screen: DATA_LAYER_CONSTANTS.CHOOSE_DELIVERY_METHOD,
                    zipcode: selectedAddress.address.postalCode
                });
            } else {
                const $errorWrapper = $form.find('.invalid-feedback');
                $errorWrapper.text(response.reasons[0].message);
                $contentWrapper.addClass('has-error');
                // In case of error from google place API, show suggestions returned from quick zip if any
                if (response.quickZipAddresses) {
                    let selectedFirstAddress = $target.data('formatted-address');
                    showQuickZipSuggestions($form, response.quickZipAddresses, selectedFirstAddress);
                }
            }
        }
    });
};

const selectCurrentLocation = (e) => {
    const $target = $(e.currentTarget);
    const $form = $target.closest('form');
    const $contentWrapper = $target.closest('.shipping-selector-content');

    const errorMsg = $target.find('.error').text();
    const $errorWrapper = $form.find('.invalid-feedback');

    if (!navigator.geolocation) {
        $errorWrapper.text(errorMsg);
        $contentWrapper.addClass('has-error');
    } else {
        $errorWrapper.empty();
        $contentWrapper.removeClass('has-error');
        navigator.geolocation.getCurrentPosition(
            (position) => {
                var geoLocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                };
                selectAddress({ currentTarget: $target[0] }, geoLocation);
            },
            () => {
                $errorWrapper.text(errorMsg);
                $contentWrapper.addClass('has-error');
            }
        );
    }
};

const clearSearch = (e) => {
    const $button = $(e.currentTarget);
    const $input = $button.closest('form').find('input');
    $input.val('').trigger('input');
};

const showSelectShippingMethodPopup = (e, data) => {
    const url =
        window.selectShippingMethodModalURL + '?trigger=' + (data && data.trigger) + '&includeAddress=' + (data && data.includeAddress) + '&productId=' + (data && data.productId);
    $.spinner().start();

    $.ajax({
        url: url,
        type: 'get',
        /**
         * Handles the success response of the shipping component AJAX call.
         * @param {Object} responseHTML - The response object from the AJAX call.
         */
        success: function (responseHTML) {
            $.spinner().stop();

            // hide the replacement products modal
            var $replacementProductsModal = $('#replacementProductsModal');
            $replacementProductsModal.modal('hide');

            const modalID = 'change-or-select-sipping-method-modal';
            getModalHtmlElement(modalID);

            const $modal = $(`.${modalID}`);
            const $virtualModal = $(responseHTML);

            // Update modal header
            const $modalHeader = $modal.find('.modal-header');
            $modalHeader.find('.modal-header-title').remove();
            $modalHeader.find('.arrow-left').addClass('d-none');
            $virtualModal.find('.modal-header-title').appendTo($modalHeader);

            // Update modal body
            const $modalBody = $modal.find('.modal-body');
            $modalBody.empty();
            $virtualModal.find('.modal-body .body-wrapper').appendTo($modalBody);

            // Update data attributes
            const $modalDialog = $modal.find('.modal-dialog');
            $modalDialog.attr('data-refresh-url', $virtualModal.attr('data-refresh-url'));
            $modalDialog.attr('data-refresh-selector', $virtualModal.attr('data-refresh-selector'));

            // Clean up
            $virtualModal.remove();

            // Show modal
            $modal.modal('show');

            // Track event
            window.dataLayer.push({
                event: DATA_LAYER_CONSTANTS.DELIVERY_SCREEN_EVENT,
                delivery_method_picker_screen: DATA_LAYER_CONSTANTS.ZIPCODE
            });
        }
    });
};

const showSuggestionsWrapper = (e) => {
    const $input = $(e.currentTarget);
    $input.closest('form').find('.address-suggestions-wrapper').addClass('open');
};

module.exports = {
    methods: {
        searchAddress: searchAddress,
        selectAddress: selectAddress,
        selectCurrentLocation: selectCurrentLocation,
        highlightSearchTerm: highlightSearchTerm
    },
    showSelectorAddressInput: () => {
        $('body').on('click', '.shipping-selector-address-search .prefilled-addr-link', (e) => {
            const $elmt = $(e.currentTarget);
            const $form = $elmt.closest('.shipping-selector-address-search').find('form');
            $form.removeClass('d-none');
            $elmt.addClass('d-none');
            if ($form.find('input').val().length > 0) {
                $form.addClass('typing');
            } else {
                $form.removeClass('typing');
            }
        });
    },
    handleSearchAddressInput: () => {
        $('body').on('input', '#address-search-input', searchAddress);
    },
    showSuggestionsWrapper: () => {
        $('body').on('focus input', '#address-search-input', showSuggestionsWrapper);
    },
    selectAddress: () => {
        const $body = $('body');
        // Handle click on the address suggestions
        $body.on('click', '.address-suggestions-wrapper li:not(.geolocation)', selectAddress);

        // Handles click on enter (submit)
        $body.on('submit', 'form.address-search-form', (e) => {
            e.preventDefault();
            const $form = $(e.currentTarget);
            const $address = $form.find('.address-suggestions-wrapper.open li:not(.geolocation)').first();
            if ($address && $address.length === 1) {
                selectAddress({ currentTarget: $address[0] });
            }
        });

        // Handle click on search icon
        $body.on('click', 'form.address-search-form .search-button', (e) => {
            e.preventDefault();
            const $form = $(e.currentTarget).closest('form');
            const $address = $form.find('.address-suggestions-wrapper li:not(.geolocation)').first();
            if ($address && $address.length === 1) {
                selectAddress({ currentTarget: $address[0] });
            }
        });
    },
    refreshShippingComponents: () => {
        $('body').on('shippingSelector:refresh', (e, data) => {
            refreshComponent(data);
        });
        $('body').on('shippingHeader:refresh', () => {
            const $wrapper = $('.shipping-header-wrapper');
            const data = prepareRefreshEventData($wrapper);
            refreshComponent(data);
        });
    },
    selectGeolocation: () => {
        $('body').on('click', '.address-suggestions-wrapper li.geolocation', selectCurrentLocation);
    },
    showShippingMethodInfo: () => {
        $('body').on('click', '.js-show-shipping-cost-info', function (e) {
            e.preventDefault();
            const $this = $(this);
            var dataUrl = $this.data('href');

            $.ajax({
                url: appendSelectedAddress(dataUrl),
                method: 'GET',
                dataType: 'json',
                /**
                 * @param {*} data Response from server
                 * Success response.
                 */
                success: function (data) {
                    getModalHtmlElement('shipping-info-modal');
                    var $modal = $('#shippingModal');
                    var $modalBody = $modal.find('.modal-body');

                    // Transfer refresh component data
                    transferRefreshEventData($this, $modal);

                    var parsedHtml = parseHtml(data.renderedTemplate);
                    $modalBody.empty().append(parsedHtml.body);
                    $modal.find('.modal-header .modal-header-title').empty().append(parsedHtml.header);
                    $modal.modal('show');
                    window.dataLayer.push({
                        event: DATA_LAYER_CONSTANTS.DELIVERY_SCREEN_EVENT,
                        delivery_method_picker_screen:
                            data.shippingMethod.ID === DATA_LAYER_CONSTANTS.HOME_DELIVERY_ID
                                ? DATA_LAYER_CONSTANTS.CONFIRM_HOME_DELIVERY
                                : DATA_LAYER_CONSTANTS.CONFIRM_STORE_COLLECTION,
                        zipcode: data.postalCode,
                        store_id: data.storeID,
                        shipping_tier: data.shippingMethod.ID === DATA_LAYER_CONSTANTS.HOME_DELIVERY_ID ? DATA_LAYER_CONSTANTS.HOME_DELIVERY : DATA_LAYER_CONSTANTS.STORE_COLLECTION
                    });
                },
                /**
                 * Error Handler
                 */
                error: function () {},
                /**
                 * Complete Handler
                 */
                complete: function () {
                    $('body').trigger('storeAvailability:clearInterval');
                }
            });
        });
    },
    closeShippingModal: () => {
        $('body').on('click', '.close-shipping-modal', function () {
            $('#shippingModal').modal('hide');
            $('body').trigger('storeAvailability:clearInterval');
        });
    },

    submitShippingMethodPref: () => {
        $('body').on('click', '.js-shipping-method-submit', function (e) {
            e.preventDefault();
            const $this = $(this);
            const $modal = $('#shippingModal');
            var url = $this.closest('a').attr('href');

            $.ajax({
                url: appendSelectedAddress(url),
                method: 'post',
                dataType: 'json',
                /**
                 * @param {*} response Response from server
                 * Success response.
                 */
                success: function (response) {
                    if (response.productId) {
                        window.sessionStorage.setItem('pendingCartProduct', response.productId);
                    }

                    if (response.needReload) {
                        window.location.reload();
                    } else {
                        const $component = $('.shipping-selector-container').first();
                        const refreshData = prepareRefreshEventData($component);
                        $('body').trigger('shippingSelector:refresh', refreshData);
                        $component.addClass('shipping-summary-container');
                        // Header component refresh
                        $('body').trigger('shippingHeader:refresh');
                        $modal.modal('hide');
                        $('body').trigger('accountPage:refresh');
                    }
                },
                /**
                 * Error Handler
                 */
                error: function () {
                    $modal.modal('hide');
                }
            });
        });
    },

    clearSearch: () => {
        $('body').on('click', 'form.address-search-form .reset-button', clearSearch);
    },

    closeSuggestionsPopup: () => {
        $('body').on('click', (e) => {
            const $target = $(e.target);
            const $form = $('form.address-search-form');
            if ($form.has($target).length === 0) {
                $form.find('.address-suggestions-wrapper').removeClass('open');
            }
        });
    },

    showSelectShippingMethodPopup: () => {
        const $body = $('body');
        $body.on('shippingMethod:select', showSelectShippingMethodPopup);
        $body.on('click', 'a.js-change-shipping-method', (e) => {
            e.preventDefault();

            const $target = $(e.currentTarget);
            const includeAddress = $target.attr('data-change-type') === 'address';
            $body.trigger('shippingMethod:select', { trigger: 'change', includeAddress: includeAddress });
        });
        $body.on('click', 'button.shipping-header-tile:not(.selected)', (e) => {
            e.preventDefault();
            $body.trigger('shippingMethod:select', { trigger: 'change' });
        });
    },

    handleModalCloseOnInputSelect: () => {
        let isTextSelected = false;

        // Listen for mouse up event anywhere on the document
        $(document).on('mouseup', function () {
            // Check if there is a text selection
            isTextSelected = window.getSelection().toString().length > 0;
        });

        // Listen for modal close event
        $('body').on('hide.bs.modal', '#shippingModal', function (event) {
            // If text was selected when the mouse was released, prevent the modal from closing
            if (isTextSelected) {
                event.preventDefault();
            }
        });
    }
};
