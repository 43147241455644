'use strict';

const $container = $('.subCategories-container');
const $openSliderBtn = $container.find('.show-slider');
const $slider = $('.subCategories-container__slider');
const $closeSliderBtn = $slider.find('.close-slider');
const $item = $('.subCategories-container__list__items__item');
const $items = $('.subCategories-container__list__items__item');

const handleOpenSlider = () => {
    $openSliderBtn.on('click', function () {
        $('<div class="slider-backdrop"></div>').appendTo(document.body);
        $slider.addClass('active');
    });
};

const handleCloseSlider = () => {
    $closeSliderBtn.on('click', function () {
        $('.slider-backdrop').remove();
        $slider.removeClass('active');
    });
};

const selectLevel3Category = () => {
    const categoryID = $('#current-category-id').val();
    if (categoryID && $items.length > 0) {
        $items.each(function () {
            const item = $(this);
            const itemSubCategoryId = item.data('subcategory-id');
            if (itemSubCategoryId === categoryID) {
                item.addClass('selected');
            }
        });
    }
};

const unSelectLevel3Category = () => {
    $item.on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) {
            const categoryUrl = $(this).data('category-url');
            window.location.href = categoryUrl;
        } else {
            const subCategoryUrl = $(this).data('subcategory-url');
            window.location.href = subCategoryUrl;
        }
    });
};

module.exports = {
    initSubcategoryBanner: () => {
        if (!$container.length) return;
        $(document).ready(function () {
            $('.subCategories-container__list__items.owl-carousel').owlCarousel({
                margin: 24,
                loop: false,
                nav: true,
                dots: false,
                autoWidth: true,
                lazyLoad: true,
                navText: ["<i class='icon icon-chevron-right'></i>", "<i class='icon icon-chevron-right'></i>"]
            });
        });
        handleOpenSlider();
        handleCloseSlider();
        selectLevel3Category();
        unSelectLevel3Category();
    }
};
