'use script';

const oneTrustConsent = () => {
    var oneTrustUrlContent = $('.tracking-consent-onetrust').data('url');
    if (oneTrustUrlContent) {
        $('body').on('consentEvent:Show countrySelector:close', function () {
            $.ajax({
                url: oneTrustUrlContent,
                type: 'get',
                dataType: 'html',
                success: (response) => {
                    $('head').append(response);
                }
            });
        });
    }
};

const init = () => {
    var siteID = 'carrefour_be_lang';
    var languageCookie = (document.cookie.match('(^|;) *' + siteID + '=([^;]*)') || [])[2];
    if (languageCookie) {
        $('body').trigger('consentEvent:Show');
    }
};

module.exports = {
    oneTrustConsent: oneTrustConsent,
    init: init
};
