'use strict';

const einsteinFallback = require('../einstein/einsteinFallback');
const productUtils = require('../product/productUtils');
const debounce = require('lodash/debounce');

const recommendationsType = {
    spotlight: 'spotlight',
    sponsoredProductGrid: 'sponsoredProductGrid',
    shopLine: 'shopLine'
};

let sponsorTilesAndShoplineFallback = [];

/**
 * Initializes the sponsored tiles slider
 */
const initSponsorTilesSlider = () => {
    const $slider = $('.js-homepage-citrus-ads');
    if (!$slider.length) return;

    const { getProductTilesAvailabilityInformation, updateProductsWishlistStatus } = require('../product/productUtils');
    getProductTilesAvailabilityInformation();
    updateProductsWishlistStatus();

    $slider.owlCarousel({
        className: 'js-homepage-citrus-ads owl-carousel',
        margin: 0,
        nav: true,
        dots: false,
        autoWidth: true,
        lazyLoad: true,
        slideBy: 2,
        checkVisibility: false,
        navText: ['<i class="icon icon-arrow-left-circle"></i>', '<i class="icon icon-arrow-right-circle"></i>'],
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
                nav: false
            },
            768: {
                items: 2,
                slideBy: 2,
                nav: false
            },
            1024: {
                items: 2,
                slideBy: 2,
                nav: true
            }
        },
        onInitialized: () => {
            $slider.parents('.js-carousel-wrapper.d-none').removeClass('d-none');
            $('body').trigger('custom:carouselSwitcherCitrusCallback', [true]);
        }
    });
};

/**
 * Initializes the spotlight slider
 */
const initSpotlightSlider = () => {
    const $slider = $('.js-citrus-ads-spotlight-carousel');
    if (!$slider.length) return;

    const { getProductTilesAvailabilityInformation, updateProductsWishlistStatus } = require('../product/productUtils');
    getProductTilesAvailabilityInformation();
    updateProductsWishlistStatus();

    $slider.owlCarousel({
        className: 'js-citrus-ads-spotlight-carousel owl-carousel',
        margin: 0,
        nav: true,
        dots: false,
        autoWidth: true,
        lazyLoad: true,
        slideBy: 2,
        navText: ['<i class="icon icon-chevron-left fs-icon-s"></i>', '<i class="icon icon-chevron-right fs-icon-s"></i>'],
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
                nav: false
            },
            375: {
                items: 2,
                slideBy: 2,
                nav: false
            },
            1024: {
                items: 2,
                slideBy: 2
            },
            1280: {
                items: 2,
                slideBy: 2
            },
            1600: {
                items: 3,
                slideBy: 3
            }
        }
    });
};

/**
 * Pushes the product list object to GTM
 * @param {string} gtmProductList - Product list
 */
const pushProductListToGtm = (gtmProductList) => {
    if (gtmProductList && gtmProductList !== 'null') {
        try {
            $('body').trigger('citrus:viewItemList', typeof gtmProductList === 'string' ? JSON.parse(gtmProductList) : gtmProductList);
        } catch (e) {} // eslint-disable-line no-empty
    }
};

/**
 * Pushes the Citrus widget name to GTM
 * @param {string} widgetName - Widget name
 */
const pushViewInsertToGtm = (widgetName) => {
    if (widgetName && widgetName !== 'null') {
        try {
            $('body').trigger('citrus:viewInsert', widgetName);
        } catch (e) {} // eslint-disable-line no-empty
    }
};

/**
 * Sets width of owl carousel items dynamically based on media width
 */
const adjustOwlItemWidths = function () {
    const screenWidth = window.innerWidth;
    $('.citrusad-banner-carousel .owl-item').toggleClass('owl-item-custom', screenWidth >= 480 && screenWidth < 1024);
};
$(window).on('resize', debounce(adjustOwlItemWidths, 100));

/*
 * Initializes the banner carousel with autoplay
 * @param {integer} timing - Time in seconds from custom preference
 */
const initBannerCarousel = (timing) => {
    const $slider = $('.js-citrus-ad-banner-carousel');
    if (!$slider.length) return;

    var autoplayTiming = timing * 1000; // seconds to miliseconds

    $slider.owlCarousel({
        className: 'js-citrus-ad-banner-carousel owl-carousel',
        margin: 0,
        nav: false,
        dots: false,
        lazyLoad: true,
        autoWidth: false,
        slideBy: 1,
        autoplay: true,
        autoplayTimeout: autoplayTiming,
        loop: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
                autoWidth: false,
                nav: false
            },
            480: {
                items: 1,
                slideBy: 1,
                margin: 8,
                autoWidth: true,
                nav: false
            },
            1024: {
                items: 1,
                autoWidth: false,
                slideBy: 1
            }
        },
        onInitialized: () => {
            $slider.removeClass('d-none');
            $('.sponsor-title').show();
            adjustOwlItemWidths();
        }
    });
};

/**
 * Gets the sponsored ad data
 * @param {string} url - URL to fetch the ad data
 * @returns {object[]} - Array of objects containing ad data
 */
const getSponsoredProductTilesFromCitrusAds = async (url) => {
    const response = await new Promise((resolve) => {
        $.get(url).done((data) => {
            resolve(data);
        });
    });

    return response.productData || [];
};

/**
 * Gets the shopline ad data
 * @param {string} url - URL to fetch the ad data
 * @returns {object[]} - Array of objects containing ad data
 */
const getShopLineAds = async (url) => {
    const citrusData = await new Promise((resolve) => {
        $.get(url).done((data) => {
            resolve(data);
        });
    });

    return citrusData && citrusData.shopLines;
};

/**
 * Gets the spotlight ad data
 * @param {string} url - URL to fetch the ad data
 * @returns {object[]} - Array of objects containing ad data
 */
const getSpotlightAds = async (url) => {
    const citrusData = await new Promise((resolve) => {
        $.get(url).done((data) => {
            resolve(data);
        });
    });

    return citrusData && citrusData.spotlights;
};

/**
 * Listen for Citrus Ad events
 */
const initShopLineEvents = () => {
    $('body').on('click', '.js-citrus-ad-shopline__button', function () {
        const $selectedButton = $(this);
        const $parentComponent = $selectedButton.closest('.js-citrus-ad-shopline');
        const targetId = $selectedButton.data('target');
        const $targetProduct = $parentComponent.find(`[data-citrus-ad-product="${targetId}"]`);
        $parentComponent.find('.js-citrus-ad-shopline__button').removeClass('selected');
        $parentComponent.find('.js-citrus-ad-shopline__product').addClass('d-none');

        $selectedButton.addClass('selected');
        $targetProduct.removeClass('d-none');
    });
};

const handleCitrusAdComponent = (isCitrusEnabled) => {
    const citrusAdComponents = {
        sponsoredProductGrid: async (grid) => {
            const $grid = $(grid);

            let citrusData = [];
            sponsorTilesAndShoplineFallback = [];
            const sponsoredEinsteinFallbackEnabled = $grid.data('einstein-sponsored-fallback-enabled');

            if (isCitrusEnabled) {
                citrusData = await getSponsoredProductTilesFromCitrusAds($grid.attr('data-citrus-tiles-url'));
            }

            if (sponsoredEinsteinFallbackEnabled && citrusData.length === 0 && sponsorTilesAndShoplineFallback.length === 0) {
                sponsorTilesAndShoplineFallback = await einsteinFallback.getEinsteinFallbackTiles(productUtils.fetchQstringParam('cgid'), recommendationsType.sponsoredProductGrid);
            }

            return function () {
                const tiles = $(grid).find('.js-sponsoredProduct');

                citrusData = citrusData.length > 0 || !sponsoredEinsteinFallbackEnabled ? citrusData : sponsorTilesAndShoplineFallback;

                tiles.each((index, tile) => {
                    const citrusDataObj = citrusData[index];
                    if (citrusDataObj && citrusDataObj.html) {
                        $(tile).parents('.js-sponsored-product-tile').removeClass('d-none');
                        $(tile).html(citrusDataObj.html);
                    } else {
                        $(tile).parents('.js-sponsored-product-tile').addClass('d-none');
                    }
                });

                if (citrusData === sponsorTilesAndShoplineFallback) {
                    sponsorTilesAndShoplineFallback.splice(0, tiles.length);
                }

                $('.js-sponsored-tile .js-product-tile').each(function () {
                    pushViewInsertToGtm($(this).attr('data-item-list-name'));
                    pushProductListToGtm($(this).attr('data-gtm-product-list'));
                });

                window.watchTiles = true;
                $('body').trigger('tile:needUpdate');
            };
        },

        shopLine: async (grid) => {
            const $grid = $(grid);
            const url = $grid.data('citrus-shopline-url'); // CitrusAd-ShopLine
            if (!url) {
                return function () {};
            }
            let shopLines = [];
            const shoplineEinsteinFallbackEnabled = $grid.data('einstein-sponsored-fallback-enabled');

            if (isCitrusEnabled) {
                shopLines = await getShopLineAds(url);
            }
            shopLines = shopLines.every((item) => item === null) ? [] : shopLines;
            const emptyShopLinesResponse = !shopLines.length;

            if (shoplineEinsteinFallbackEnabled && emptyShopLinesResponse && sponsorTilesAndShoplineFallback.length === 0) {
                sponsorTilesAndShoplineFallback = await einsteinFallback.getEinsteinFallbackTiles(productUtils.fetchQstringParam('cgid'), recommendationsType.shopLine);
            }

            return function () {
                // if Einstein fallback, replace skeletons with tiles,
                // if Shopline, replace the entire div with HTML response
                const tileSelector = emptyShopLinesResponse
                    ? '.js-product-tile-skeleton' + (app.isMobile() ? ':not(.js-citrus-ad-shopline-banner-placeholder)' : '')
                    : '.js-citrus-ad-shopline';
                const tiles = $grid.find(tileSelector);

                shopLines = !emptyShopLinesResponse || !shoplineEinsteinFallbackEnabled ? shopLines : sponsorTilesAndShoplineFallback;

                tiles.each((index, tile) => {
                    const $tile = $(tile);
                    if (!shopLines.length) {
                        $tile.parents('.js-citrus-ad-shopline__region').addClass('d-none');
                        return;
                    }

                    const shoplineObj = shopLines[index];
                    if (!shoplineObj) return;

                    if (shoplineEinsteinFallbackEnabled && emptyShopLinesResponse && sponsorTilesAndShoplineFallback.length && $tile.hasClass('js-einstein-fallback-img')) {
                        $tile.removeClass('skeleton-mb-banner');
                        $('.js-einstein-shopline-fallback-banner').removeClass('d-none');
                        return;
                    }

                    $tile.html($.parseHTML(shoplineObj.html));

                    pushProductListToGtm($tile.children().attr('data-gtm-product-list'));
                    pushViewInsertToGtm($tile.children().attr('data-item-list-name'));

                    // Remove d-none class that is intended to hide the button when product availability is being checked
                    const $regionWrappers = $('.js-citrus-ad-shopline__region');
                    if ($regionWrappers && $regionWrappers[index]) {
                        $($regionWrappers[index]).removeClass('d-none');
                    }
                    $tile.find('.js-product-tile .add-to-cart-wrapper.d-none').removeClass('d-none');
                });

                if (shopLines === sponsorTilesAndShoplineFallback) {
                    sponsorTilesAndShoplineFallback.splice(0, tiles.length);
                }
            };
        },

        spotlight: async (grid) => {
            const $grid = $(grid);
            const url = $grid.data('citrus-spotlight-url'); // CitrusAd-Spotlight
            if (!url) {
                return function () {};
            }
            let spotlights = [];
            if (isCitrusEnabled) {
                spotlights = await getSpotlightAds(url);
            }
            const spotlightEinsteinFallbackEnabled = $grid.data('einstein-spotlight-fallback-enabled');
            if (!spotlights || !spotlights.length) {
                if (!spotlightEinsteinFallbackEnabled && isCitrusEnabled) {
                    // hide skeleton if fallback is not enabled and no citrus recommendations
                    $('.js-spotlight-skeleton').addClass('d-none');
                    return function () {};
                }

                const fallbackRecommendations = await einsteinFallback.getEinsteinFallbackTiles(productUtils.fetchQstringParam('cgid'), recommendationsType.spotlight); // eslint-disable-line no-unused-vars

                return function () {
                    // fallback code
                    if (!fallbackRecommendations || !fallbackRecommendations.html) {
                        // hide skeleton if no recommendations
                        $('.js-spotlight-skeleton').addClass('d-none');
                        return;
                    }

                    const $einsteinFallbackPlaceholders = $(grid).find('.js-einstein-spotlight-fallback');
                    $einsteinFallbackPlaceholders.each((index, placeholder) => {
                        const $placeholder = $(placeholder);
                        const $einsteinFallbackWrapper = $placeholder.closest('.js-einstein-spotlight-fallback-wrapper');
                        const $einsteinBanner = $einsteinFallbackWrapper.find('.js-einstein-banner');
                        const $skeletonWrapper = $einsteinFallbackWrapper.parent('.js-spotlight-wrapper').find('.js-spotlight-skeleton');

                        $placeholder.html($.parseHTML(fallbackRecommendations.html));
                        $einsteinFallbackWrapper.removeClass('d-none');
                        if (app.isMobile()) {
                            $einsteinBanner.removeClass('d-none');
                        }
                        $skeletonWrapper.addClass('d-none');
                    });

                    initSpotlightSlider();
                };
            }

            return function () {
                const placeholders = $(grid).find('.js-citrus-ad-spotlight');
                placeholders.each((index, placeholder) => {
                    const spotlightObj = spotlights[index];
                    if (!spotlightObj) return;

                    $(placeholder).html($.parseHTML(spotlightObj.html));
                    $(placeholder).next('.js-spotlight-skeleton').addClass('d-none');
                    $('.js-einstein-spotlight-fallback-wrapper').addClass('d-none');

                    pushProductListToGtm($(placeholder).children().attr('data-gtm-product-list'));
                    pushViewInsertToGtm($(placeholder).children().attr('data-item-list-name'));
                });

                initSpotlightSlider();
            };
        },

        homePageCarousel: async () => {
            const citrusAdsPlaceholder = $('.js-homepage-citrus-ads');
            const url = citrusAdsPlaceholder.attr('data-citrus-ads-homepage-url'); // CitrusAd-SponsoredTiles

            let response = {};

            if (isCitrusEnabled) {
                response = await new Promise((resolve) => {
                    $.get(url).done((data) => {
                        resolve(data);
                    });
                });
            }

            return function () {
                if (response.success && response.productData.length) {
                    response.productData.forEach((data) => {
                        citrusAdsPlaceholder.append(data.html);
                    });

                    initSponsorTilesSlider();
                    pushProductListToGtm(response.productListDl);
                    pushViewInsertToGtm('citrus carousel');

                    return;
                }

                $('body').trigger('custom:carouselSwitcherCitrusCallback', [false]);
            };
        },

        bannerCarousel: async () => {
            const citrusAdsPlaceholder = $('.js-citrus-ad-banner-carousel');
            const url = citrusAdsPlaceholder.attr('data-citrus-ads-bannercarousel-url'); // CitrusAd-BannerCarousel

            if (!url) {
                return function () {};
            }

            const response = await new Promise((resolve) => {
                $.get(url).done((data) => {
                    resolve(data);
                });
            });

            return function () {
                if (!response || !response.success || !response.data.length) {
                    const $bannerFallback = $('.js-citrus-fallback-ad-banner');

                    if ($bannerFallback.length) {
                        $bannerFallback.removeClass('d-none');
                        $('.js-fallback-sponsor-title').removeClass('d-none');
                    }

                    return;
                }

                response.data.forEach((data) => {
                    $(citrusAdsPlaceholder).append($.parseHTML(data.html));
                });

                initBannerCarousel(response.carousel.timing);
            };
        }
    };

    $(document).ready(function () {
        const singleAdFunctions = $('[data-citrus-ad!=""][data-citrus-ad]')
            .map(function (i, ad) {
                const adAttr = $(this).attr('data-citrus-ad');
                return citrusAdComponents[adAttr](ad);
            })
            .toArray();
        const multiAdsFunctions = $('[data-citrus-ad-list!=""][data-citrus-ad-list]')
            .map(function (i, ad) {
                const adListJSON = $(this).attr('data-citrus-ad-list');
                let list = [];
                try {
                    list = JSON.parse(adListJSON);
                    // eslint-disable-next-line no-empty
                } catch (e) {}

                if (list) {
                    return list.map((adAttr) => {
                        return citrusAdComponents[adAttr](ad);
                    });
                }
                return [];
            })
            .toArray();

        Promise.all([...singleAdFunctions, ...multiAdsFunctions.flat()])
            .then((returnFunctions) => {
                returnFunctions.forEach((myFunction) => {
                    myFunction();
                });

                require('./citrusAdTracking').init();
            })
            .catch(() => {
                // console.log(e);
            });
    });
};

const initCitrusAdComponent = () => {
    $('body').on('cookie:callback', () => {
        const url = $('.citrusAd[data-citrusad-url]').attr('data-citrusad-url');

        $.ajax({
            url: url,
            method: 'GET',
            success: (data) => {
                handleCitrusAdComponent(data.adsEnabled);
            }
        });
    });
};

module.exports = {
    initCitrusAdComponent: initCitrusAdComponent,
    initShopLineEvents: initShopLineEvents
};
