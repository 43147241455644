'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('base/util');
require('./global/_app');

$(document).ready(function () {
    $('.removable-icon').on('click', function () {
        $(this).closest('.tag-removable').remove();
    });
});

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/accountDropDown'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/miniCartPreview'));
    processInclude(require('./components/subCategoriesBanner'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/storeSelectorModal'));
    processInclude(require('./carousel'));
    processInclude(require('./components/oneTrustConsent'));
    processInclude(require('./components/footerCollapsibleItem'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/breadcrumbs'));
    processInclude(require('./citrusAd/citrusAd'));
    processInclude(require('./product/tile'));
    processInclude(require('./notification'));
    processInclude(require('./components/shippingSelector'));
    processInclude(require('./tooltipTippy'));
    processInclude(require('./components/timeSlotSelector'));
    processInclude(require('./gtm'));
    processInclude(require('wishlist_carrefour/product/wishlistHeart'));
    processInclude(require('app_carrefour/product/pendingAddToCart'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('./libs/jquery.ellipsis');
require('select2');
require('owl.carousel');
require('lazysizes');
