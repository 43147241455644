'use strict';

var base = require('base/components/countrySelector');

/**
 * Module for handling country selection functionality.
 * @module countrySelector
 */

module.exports = {
    baseFeatures: base,
    countrySelector: () => {
        var $countrySelector = $('.country-selector-popup');
        $('.country-selector-popup a').on('click', function (e) {
            e.preventDefault();
            $.spinner().start();
            const $this = $(this);
            const action = $('.page').data('action');
            const localeCode = $this.data('locale');
            const localeCurrencyCode = $this.data('currencycode');
            const queryString = $('.page').data('querystring');
            const url = $countrySelector.data('url');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: action
                },
                /**
                 * Handles the success response of the country selection AJAX call.
                 * @param {Object} response - The response object from the AJAX call.
                 */
                success: function (response) {
                    $.spinner().stop();
                    if (response && response.locale === localeCode) {
                        $('.country-selector__container').hide();
                        $('.country-selector__backdrop').hide();
                        $('body').trigger('countrySelector:close');
                    } else if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                }
            });
        });
    }
};
