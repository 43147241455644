'use strict';

var debounce = require('lodash/debounce');
var base = require('base/components/menu');

const MOBILE_MENU_SELECTOR = 'header > nav .mobile-nav';
const DESKTOP_MENU_SELECTOR = 'header > nav .desktop-header';
const EVENT_OPEN_MENU = 'navigation:open-menu';
const EVENT_CLOSE_MENU = 'navigation:close-menu';
const $productTab = $('.level-1-item.is-products');
const $headerWrapper = $('.header-wrapper');
var headerBannerHeight = 0;
var mobileSubheaderHeight = 0;
var desktopSubheaderHeight = 0;
const $desktopMenu = $(DESKTOP_MENU_SELECTOR);
const isPLP = !!$headerWrapper.parent('[data-page-type="plp"]').length;

const $desktopSubHeaderSection = $desktopMenu.find('.sub-header-section');
if ($desktopSubHeaderSection.length) {
    desktopSubheaderHeight = $desktopSubHeaderSection.height();
}

const getDesktopThreshold = () => {
    return $desktopMenu.find('.header-custom-container').height() + headerBannerHeight + desktopSubheaderHeight;
};

const scrollHandlerForMenu = () => {
    const $mobileMenu = $(MOBILE_MENU_SELECTOR);
    const scrollPosition = $(window).scrollTop();
    const $mobileSuggestions = $('.mobile-search-wrapper .suggestions-wrapper');
    const $cancelSearch = $('.cancel-search');
    const $searchField = $('.search-field');
    const $mobileSubHeaderSection = $mobileMenu.find('.sub-header-section');
    const $cfHeaderBanner = $('.cf-header-banner');
    if ($cfHeaderBanner.length) {
        headerBannerHeight = $cfHeaderBanner.height();
    }

    if ($mobileSubHeaderSection.length) {
        mobileSubheaderHeight = $mobileSubHeaderSection.height();
    }

    const mobileThreshold = $mobileMenu.find('.level-0-wrapper').height() + headerBannerHeight + mobileSubheaderHeight;
    const desktopThreshold = getDesktopThreshold();

    if ($mobileMenu.css('display') !== 'none') {
        if (scrollPosition < mobileThreshold) {
            $mobileMenu.removeClass('scroll');
            $mobileSuggestions.removeClass('scroll-suggestions');
        } else {
            $mobileMenu.addClass('scroll');
            $mobileSuggestions.addClass('scroll-suggestions');
            $cancelSearch.hide();
            $searchField.val('');
        }
    }

    if ($desktopMenu.css('display') !== 'none') {
        if (scrollPosition < desktopThreshold) {
            if (isPLP && $desktopMenu.hasClass('attached')) {
                $productTab.trigger('click');

                return;
            }
            $desktopMenu.addClass('animate');
            $desktopSubHeaderSection.slideDown(150, () => {
                $desktopMenu.removeClass('scroll animate');
                if ($desktopMenu.hasClass('attached')) {
                    $productTab.addClass('is-active');
                }
                $desktopSubHeaderSection.css('display', '');
            });
        } else if (scrollPosition > desktopThreshold) {
            if (isPLP) {
                $productTab.removeClass('is-active');
                $desktopMenu.removeClass('attached');
                $headerWrapper.removeClass('attached');
            }
            $desktopMenu.addClass('animate');
            $desktopSubHeaderSection.slideUp(150, () => {
                $desktopMenu.addClass('scroll');
                $productTab.removeClass('is-active');
                $desktopMenu.removeClass('animate');
                $desktopSubHeaderSection.css('display', '');
            });
        }
    } else if (!$desktopMenu.hasClass('attached')) {
        $productTab.removeClass('is-active');
    }

    // Hide category bar on scroll
    $desktopMenu.removeClass('show-by-click');
};

const initDesktopMenuSlider = () => {
    const $header = $('header > nav > .header');
    const $categoryWrapper = $('.level-2-cat-wrapper');
    const $slider = $header.find('.level-2-nav:not(.mobile-level-2-nav) .level-2-cat-wrapper:not(.owl-loaded)');

    if ($slider.length === 0) {
        return;
    }

    // Check the screen width
    const screenWidth = window.innerWidth;

    if (screenWidth >= app.grid.lg) {
        // Initialize Owl Carousel
        $slider.owlCarousel({
            margin: 0,
            loop: false,
            nav: true,
            dots: false,
            lazyLoad: true,
            navText: ["<i class='icon icon-chevron-left'></i>", "<i class='icon icon-chevron-right'></i>"],
            autoRefreshInterval: 10,
            responsive: {
                0: {
                    items: 6,
                    slideBy: 6
                },
                1280: {
                    items: 10,
                    slideBy: 10
                },
                1440: {
                    items: 11,
                    slideBy: 11
                },
                1600: {
                    items: 12,
                    slideBy: 12
                }
            },
            onInitialized: () => {
                $categoryWrapper.removeClass('cat-wrapper-fallback');
            }
        });
    }
};

const delayedInitDesktopMenuSlider = () => {
    setTimeout(() => {
        initDesktopMenuSlider();
        $(window).resize(debounce(initDesktopMenuSlider, 10));
    }, 10);
};

const handleClickOnNavigation = () => {
    $desktopMenu.find('.level-1-item.has-sub-items').on('click', (e) => {
        e.preventDefault();
        let desktopThreshold = getDesktopThreshold();
        let isAttached = $desktopMenu.attr('data-type') === 'attached';
        let onTopOfPage = window.scrollY < desktopThreshold;
        if (onTopOfPage && isAttached) {
            // Basically HP or PLP. Click on button will show or hide category bar => Will create a layout shift
            $desktopMenu.toggleClass('attached');
            $headerWrapper.toggleClass('attached');
        } else {
            // Other scenarios => Toggle category bar. No layout shift
            $desktopMenu.toggleClass('show-by-click');
        }
        $productTab.toggleClass('is-active');
    });
};

const handleSelectedCategory = () => {
    const $elmt = $('#current-level-1-category-id');

    if ($elmt.length > 0) {
        const currentCategory = $elmt.val();
        const $menuItem = $(`.menu-category-tile > a[data-cid=${currentCategory}]`);
        if ($menuItem.length > 0) {
            $menuItem.addClass('selected');
        }
    }
};

const handleSelectedNavigationHeader = () => {
    const currentPath = window.location.pathname;
    const fullPath = window.location.href;

    const $level1Items = $('.navbar-actions > a, .actions-wrapper a, .desktop-header .level-1-nav .level-1-item:not(.is-products)');
    $level1Items.each((i, item) => {
        const $item = $(item);
        const itemPath = $item.prop('tagName') === 'A' ? $item.attr('href') : $item.find('a').attr('href');
        if (itemPath === currentPath || itemPath === fullPath) {
            $item.addClass('is-active');
        }
    });
};

const closeMenu = () => {
    const $mobileMenu = $(MOBILE_MENU_SELECTOR);
    $mobileMenu.find('.close-btn-wrapper').on('click', () => {
        $mobileMenu.removeClass('open');
        $('body').trigger(EVENT_CLOSE_MENU);
    });
};

const openMenu = () => {
    const $mobileMenu = $(MOBILE_MENU_SELECTOR);
    $mobileMenu.find('.open-btn-wrapper').on('click', () => {
        $mobileMenu.addClass('open');
        $('body').trigger(EVENT_OPEN_MENU);
    });
};

const adaptPageToMenu = () => {
    const $body = $('body');
    $body.on(EVENT_OPEN_MENU, () => {
        $body.addClass('no-scroll');
    });
    $body.on(EVENT_CLOSE_MENU, () => {
        $body.removeClass('no-scroll');
    });
};

const sendCloseCookieEvent = () => {
    const url = $('.js-url-close-banner').attr('data-url-close-banner');
    $.post(url);
};

const closeHeaderBanner = () => {
    const $cfHeaderBanner = $('.cf-header-banner');
    $cfHeaderBanner.find('.close').on('click', () => {
        $cfHeaderBanner.addClass('d-none');
        sendCloseCookieEvent();
    });
};

const copyCouponCode = () => {
    const $cfHeaderBanner = $('.cf-header-banner');
    $cfHeaderBanner.find('.js-btn-copy-code').on('click', () => {
        var value = $('.js-coupon-code').attr('data-value');
        navigator.clipboard.writeText(value);
        $('.cf-header-banner__coupon').addClass('activated');
    });
};

module.exports = {
    baseFeatures: base,
    switchMobileMenuOnScroll: () => {
        scrollHandlerForMenu(); // Call on page load
        $(document).on('scroll scrollstart scrollstop resize', debounce(scrollHandlerForMenu, 10));
        document.addEventListener('touchmove', debounce(scrollHandlerForMenu, 10), { passive: true });
    },
    openMenu,
    closeMenu,
    adaptPageToMenu,
    delayedInitDesktopMenuSlider,
    handleClickOnNavigation,
    handleSelectedCategory,
    handleSelectedNavigationHeader,
    closeHeaderBanner,
    copyCouponCode
};
