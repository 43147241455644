'use strict';

module.exports = function () {
    if (!app.checkBreakPoint('lg')) {
        var selector = '.footer-column .title';
        $('body').on('click', selector, function (e) {
            e.preventDefault();
            $(this).parents('.footer-column').toggleClass('active');

            if ($(this).parents('.footer-column').hasClass('active')) {
                $(this).attr('aria-expanded', true);
            } else {
                $(this).attr('aria-expanded', false);
            }
        });
    }
};
