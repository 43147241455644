'use strict';

/**
 *
 * @param {string} cgid - Category ID
 * @param {string} type - Indicates the type of the recommendations (spotlight, shopLine, sponsoredProductGrid)
 * @returns {object[]} - Array of recommendations
 */
const getEinsteinFallbackTiles = async (cgid, type) => {
    const url = new URL($('[data-einstein-fallback-url]').attr('data-einstein-fallback-url'));

    if (!url) return [];

    if (cgid) url.searchParams.set('cgid', cgid);
    if (type) url.searchParams.set('type', type);

    const response = await new Promise((resolve) => {
        $.get(url).done((data) => {
            resolve(data);
        });
    });

    return response.recommendations || [];
};

module.exports = {
    getEinsteinFallbackTiles
};
