'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var $initialSuggestions = $('.initial-suggestions');
var minChars = 1;
var ENTER_KEY = 13;

var SEARCH_EVENT_TYPE = 'search';
var SEARCH_ACTION_TERM = 'term';

/**
 * Get dlEventSearch object
 * @param {Object} searchValue - Search input field value
 * @returns {Object} dlEventSearch object
 * */
function getDlEventSearch(searchValue) {
    let dlEventSearch = $('.suggestions').data('dl-event-search');
    if (!dlEventSearch) {
        dlEventSearch = {
            event: SEARCH_EVENT_TYPE,
            search_selection: searchValue,
            search_action: SEARCH_ACTION_TERM,
            search_term: searchValue,
            product_suggestion: '',
            category_suggestion: '',
            recipes_suggestion: '',
            inspiration_suggestion: ''
        };
    } else {
        dlEventSearch.search_selection = searchValue;
        dlEventSearch.search_action = SEARCH_ACTION_TERM;
    }
    return dlEventSearch;
}

/**
 * Submit text search field
 * @param {Object} searchValue - Search input field value
 *  */
function submitSearch(searchValue) {
    if (!searchValue) {
        return;
    }
    const searchUrl = $('.header').data('action');
    const dlEventSearch = getDlEventSearch(searchValue);
    window.dataLayer.push(dlEventSearch);
    window.location.href = `${searchUrl}?q=${searchValue}`;
}

/**
 * Submit text search field
 * @param {boolean} enable - scroll toggle
 *  */
function toggleScrollOnSearch(enable) {
    $('html')[`${enable ? 'add' : 'remove'}Class`]('search-disable-scroll');
}

/**
 * Set initial suggestions placeholder text
 *  */
function setInitialSuggestionsPlaceholder() {
    const names = [];
    const NUM_SUGGESTIONS = 3;
    $('.suggestions .name').each(function (index) {
        if (index < NUM_SUGGESTIONS) {
            names.push($(this).text());
        }
    });
    const placeholder = names.join(', ');
    var $searchField = $('.search-field');
    if (placeholder) {
        $searchField.attr('placeholder', `${placeholder} ...`);
    }
}

/**
 * Set initial suggestions dropdown
 * @param {Object} $searchWrapper - dropdown wrapper object
 *  */
function insertInitialSuggestions($searchWrapper) {
    const $suggestionsWrapper = $('.suggestions-wrapper');
    if ($searchWrapper) {
        $searchWrapper.find('.suggestions-wrapper').empty().html($initialSuggestions).show();
    } else {
        $suggestionsWrapper.empty().html($initialSuggestions).show();
    }
    $initialSuggestions = $initialSuggestions.clone();
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} searchField - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(searchField) {
    var $searchField = $(searchField);
    var $scope;
    if ($searchField.parent().hasClass('search-field-wrapper')) {
        $scope = $searchField.parent();
    } else {
        $scope = $searchField;
    }
    return $scope.siblings('.suggestions-wrapper');
}

/**
 * Handle cancelation, empty search field, reposition suggestions
 */
function handleCancelation() {
    $('.suggestions').remove();
    $('.search-field').val('');
    $('.cancel-search').hide();
    $('.suggestions-wrapper').hide();
    toggleScrollOnSearch(false);
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch)
            .removeClass(iconSearch)
            .addClass(iconSearchClose)
            .attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose)
            .removeClass(iconSearchClose)
            .addClass(iconSearch)
            .attr('type', 'submit');
    }
}

/**
 * Highlight suggestions
 *
 * @param {string} htmlString - DOM element
 * @param {string} searchTerm - search term to be highlighted
 * @returns {string} outtput dom element
 */
function highlightSearchTerm(htmlString, searchTerm) {
    var $htmlElement = $(htmlString);
    // Find all elements with the class "name"
    $htmlElement.find('.name').each(function () {
        var $element = $(this);
        var innerHtml = $element.html();
        var regex = new RegExp(searchTerm, 'gi');
        var updatedHtml = innerHtml.replace(regex, function (match) {
            return '<mark>' + match + '</mark>';
        });
        $element.html(updatedHtml);
    });
    var updatedHtmlString = $htmlElement.prop('outerHTML');
    return updatedHtmlString;
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    $('.initial-suggestions').hide();
    var $suggestionsWrapper = getSuggestionsWrapper(this).empty().show();
    $.spinner().stop();

    if (typeof response !== 'object') {
        let formattedResponse;
        formattedResponse = highlightSearchTerm(response, $(this).val());
        $suggestionsWrapper.append(formattedResponse).show();
        toggleScrollOnSearch(true);
    } else {
        $suggestionsWrapper.hide();
        toggleScrollOnSearch(false);
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: () => {
                $.spinner().stop();
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        $('.suggestions-wrapper').hide();
    }
}

module.exports = function () {
    const $searchField = $('input.search-field');
    $(document).ready(function () {
        setInitialSuggestionsPlaceholder();
    });

    $('.cancel-search').on('click', () => {
        handleCancelation();
    });

    $(document).on('click', '.suggestions-wrapper .suggestions a', function (event) {
        event.preventDefault();
        const searchValue = $(this).find('span').text();
        let dlEventSearch = $('.suggestions').data('dl-event-search');
        if (!dlEventSearch) {
            dlEventSearch = getDlEventSearch(searchValue);
        }
        window.dataLayer.push(dlEventSearch);
        window.location.href = $(this).attr('href');
    });

    $searchField.each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);

        $(this).on('keyup focus', function () {
            const $currentField = $(this);
            if ($currentField.val() !== '') {
                // CHECK mobile-nav HAS CLASS SCROLL or navigation is open --> SHOW CROSS OTHERWISE SHOW BUTTON
                const $mobileNav = $('.mobile-nav');
                const isNavigationOpen = $mobileNav.hasClass('open');
                const $crossCancel = $('.cancel-search.cross-cancel');
                if (isNavigationOpen) {
                    $crossCancel.show();
                } else if (!$mobileNav.hasClass('scroll')) {
                    $('.cancel-search').show();
                } else {
                    $crossCancel.show();
                }
                $('.desktop-search-wrapper .cancel-search').show();
            } else {
                insertInitialSuggestions($currentField.closest('.search-wrapper'));
                $('.initial-suggestions').show();
                toggleScrollOnSearch(false);
                $('.cancel-search').hide();
            }
        });

        // eslint-disable-next-line consistent-return
        $(this).on('keyup', function (e) {
            // Capture ENTER Key Events
            switch (e.which) {
                case ENTER_KEY:
                    if ($('.mobile-search-wrapper .search-field').val().trim() !== '' || $('.desktop-search-wrapper .search-field').val().trim() !== '') {
                        submitSearch([...$('.search-field')].map((elem) => $(elem).val()).find((s) => s !== ''));
                    } else {
                        return false;
                    }
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    $('.js-search-desktop').on('click', () => {
        submitSearch($('.js-search-field-desktop').val().trim());
    });

    $('.js-search-mobile').on('click', () => {
        submitSearch($('.js-search-field-mobile').val().trim());
    });

    $('body').on('click', function (e) {
        const $suggestions = $('.suggestions');
        if (!$suggestions.has(e.target).length && !$(e.target).hasClass('search-field')) {
            $suggestions.hide();
            toggleScrollOnSearch(false);
        }
    });
};
