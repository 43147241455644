'use strict';

const { updateQueryParam } = require('../utils');
const { getProductTilesAvailabilityInformation } = require('../product/productUtils');
const { tippyTooltip } = require('../tooltipTippy');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#miniCartPreview').length !== 0) {
        $('#miniCartPreview').remove();
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal right mini-cart-preview fade" id="miniCartPreview" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <div class="modal-header-title"></div>' +
        '    <button type="button" class="close" data-dismiss="modal">' +
        '        <i class="icon icon-x"></i>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';

    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));
    var header = $html.find('.mini-cart-header');
    var body = $html.find('.mini-cart-body');
    var footer = $html.find('.mini-cart-footer');

    return { header: header, body: body, footer: footer };
}

/**
 * Render Empty Cart Bottom
 *
 */
function getEmptyCartBottomRegion() {
    var url = $('.minicart-link').data('bottom-url');
    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'html',
        /**
         * @param {*} reponse Response from server
         * Success response.
         */
        success: function (reponse) {
            $('#miniCartPreview').find('.modal-body').find('.cart-empty').append(reponse);
            getProductTilesAvailabilityInformation();
            // init recommandation Carousel in mini cart preview modal
            $('.cart-empty.mini-cart-preview-empty')
                .find('.recommandation-carousel')
                .owlCarousel({
                    className: 'recommandation-carousel',
                    margin: 0,
                    nav: true,
                    dots: false,
                    autoWidth: true,
                    items: 1,
                    slideBy: 1,
                    navText: ['<i class="icon icon-arrow-left-circle"></i>', '<i class="icon icon-arrow-right-circle"></i>'],
                    responsive: {
                        0: {
                            nav: false
                        },
                        1024: {
                            nav: true
                        }
                    }
                });
        },
        /**
         * Error Handler
         */
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replaces the content in the modal window for popup book address.
 * @param {string} url - url to be used to retrieve mini cart preview elements
 * @param {boolean} checkIsCartEmpty - url check if cart is empty to open navbar only when not
 */
function fillOutPreviewElements(url, checkIsCartEmpty) {
    $.spinner().start();
    $('body').addClass('spinner-active');
    var preview = $('#miniCartPreview');
    preview.find('.modal-dialog').spinner().start();
    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        /**
         * @param {*} data Response from server
         * Success response.
         */
        success: function (data) {
            if (checkIsCartEmpty && data.basketModel.numItems === 0) {
                $.spinner().stop();
                $('body').removeClass('spinner-active');
                getEmptyCartBottomRegion();
                return;
            }
            var parsedHtml = parseHtml(data.renderedTemplate);
            preview.find('.modal-body').empty().html(parsedHtml.body);
            if (parsedHtml.footer.length === 0) {
                preview.find('.modal-footer').addClass('d-none');
            } else {
                preview.find('.modal-footer').empty().html(parsedHtml.footer);
            }
            preview.find('.modal-header .modal-header-title').empty().append(parsedHtml.header);
            preview.find('.modal-header .close .sr-only').text(data.closeButtonText);
            preview.find('.enter-message').text(data.enterDialogMessage);

            preview.modal('show');
            tippyTooltip();

            $('body').trigger('minicart:show', data);

            $.spinner().stop();
            $('body').removeClass('spinner-active');
        },
        /**
         * Error Handler
         */
        error: function () {
            $.spinner().stop();
            preview.modal('hide');
        }
    });
}

const openMiniCart = (checkIsCartEmpty, skipResetModal) => {
    var url = $('.minicart-link').first().attr('data-href');
    if (!url) {
        return;
    }

    if (!skipResetModal) {
        getModalHtmlElement();
    }

    fillOutPreviewElements(url, checkIsCartEmpty);
};

const handleMiniCartPreview = () => {
    $('.minicart-link').on('click', function (e) {
        e.preventDefault();
        openMiniCart();
    });

    if (window.openCartSideBar) {
        openMiniCart(true);
    }

    $('body').on('minicart:opensidenav', function () {
        openMiniCart();
    });
};

const handleMiniCartUpdate = () => {
    const $miniCartLink = $('.minicart-link');
    if ($miniCartLink.length > 0) {
        const url = $miniCartLink.first().attr('data-href');
        fillOutPreviewElements(url);
    }
};

const updateQuantityWithMinusAndPlus = () => {
    window.updateQuantityTrigger = {};
    $('body').on('click', '.js-cart-product .minus-btn, .js-cart-product .plus-btn', function () {
        var $btn = $(this);
        var $cfCard = $btn.closest('.js-cart-product');
        var delta = $btn.hasClass('plus-btn') ? 1 : -1;
        var $input = $btn.parent().find('input.quantity');
        var $quantityForm = $btn.parent();
        var qty = parseInt($input.val(), 10);
        var newQty = qty + delta;
        var maxQty = parseInt($input.attr('data-max-qty'), 10);
        var pid = $cfCard.parent().attr('data-pid');
        var updateQuantityUrl = $input.attr('data-action');
        var updatedQuantityUrl = updateQueryParam(updateQuantityUrl, 'action', delta < 0 ? 'remove' : 'add');
        $input.data('action', updatedQuantityUrl);

        if (newQty >= maxQty) {
            $cfCard.addClass('has-max-qty');
            newQty = maxQty;
        } else {
            $cfCard.removeClass('has-max-qty');
        }

        if (window.updateQuantityTrigger[pid]) {
            clearTimeout(window.updateQuantityTrigger[pid]);
        }

        if (newQty > 0) {
            $input.val(newQty);
            window.updateQuantityTrigger[pid] = setTimeout(() => {
                $quantityForm.addClass('disabled');
                $input.trigger('change');

                if ($cfCard.hasClass('has-updated-quantity') && newQty <= maxQty && delta < 0) {
                    if ($('.cart-page').length) {
                        window.location.reload();
                    } else {
                        setTimeout(() => {
                            handleMiniCartUpdate();
                        }, 100);
                    }
                    $cfCard.removeClass('has-updated-quantity');
                }
            }, 300);
        }
    });
};

$(document).ready(function () {
    /**
     * Load mini cart link totals via Ajax
     */
    function loadMiniCartPreview() {
        const $minicart = $('.minicart');

        if ($minicart.length === 0) {
            return;
        }

        const $minicartLink = $minicart.find('.minicart-link');
        $minicartLink.addClass('loading');
        $.ajax({
            url: $minicartLink.first().attr('data-href'),
            method: 'GET',
            dataType: 'json',
            /**
             * @param {*} data Response from server
             * Success response.
             */
            success: function (data) {
                if (data && data.basketModel) {
                    const $miniCart = $('.minicart');
                    $miniCart.trigger('count:update', {
                        cart: data.basketModel
                    });
                }
            }
        });
    }
    loadMiniCartPreview();

    $('body').on('minicart:loadMiniCartPreview', () => {
        loadMiniCartPreview();
    });
});

module.exports = {
    methods: {
        handleMiniCartUpdate: handleMiniCartUpdate,
        openMiniCart: openMiniCart
    },
    handleMiniCartPreview: handleMiniCartPreview,
    updateQuantityWithMinusAndPlus: updateQuantityWithMinusAndPlus
};
