'use strict';

window.app = {
    device: {
        mobile: Number('0'.replace(/\D/g, '')),
        tablet: Number('375'.replace(/\D/g, '')),
        desktop: Number('768'.replace(/\D/g, ''))
    },
    window: {
        height: () => $(window).height(),
        width: () => $(window).width()
    },
    grid: {
        xs: 0,
        sm: '375', // Should not be used. Keeped to avoid compatibility issues
        mdc: '480',
        md: '768',
        lg: '1024',
        xl: '1280',
        xxl: '1440',
        xxxl: '1600',
        xxxxl: '1920'
    },
    /**
     * Is Tablet
     * @return {boolean} - Whether is tablet
     */
    isTablet: function () {
        return window.matchMedia('(min-width:' + this.device.tablet + 'px)').matches;
    },
    /**
     * Is Desktop
     * @return {boolean} - Whether is desktop
     */
    isDesktop: function () {
        return window.matchMedia('(min-width:' + this.device.desktop + 'px)').matches;
    },
    /**
     * Is Mobile
     * @return {boolean} - Whether is mobile
     */
    isMobile: function () {
        return !this.isDesktop();
    },
    /**
     * Check if the current view is matching a grid breakpoint
     * @param {string} breakPoint Breakpoint name (xs, sm, md, lg, xl, xxl, xxxl, xxxxl)
     * @returns {boolean} True if the breakpoint matches the current viewport
     */
    checkBreakPoint: function (breakPoint) {
        if (Object.keys(this.grid).indexOf(breakPoint) < 0) {
            // Breakpoint not supported
            return false;
        }
        return window.matchMedia('(min-width:' + this.grid[breakPoint] + 'px)').matches;
    }
};
