var $body = $('body');
var $page = $('.page');

const ITEM_LIST_IDS = {
    home: 'undefined',
    searchResults: 'search results',
    pdp: 'pdp carousel',
    plp: 'category',
    frequentlypurchased: 'undefined'
};
const ITEM_LIST_NAMES = {
    home: 'home carousel',
    searchResults: 'search results',
    pdp: 'pdp carousel',
    plp: 'category',
    frequentlypurchased: 'frequently purchased list',
    replacement: 'alternative products'
};

require('./inspirationTileTracking').init();

/**
 * @description This function is used to update the dataLayer object
 * @param {Object} dataLayerObj - dataLayer object
 * @param {number} index - index of the product
 * @param {string} action - action of the product
 * @param {string} replacementId - the id of the product that the list of substitution items are for
 * @param {jQuery} $productTile - Product tile jQuery object
 * @returns {void} - returns nothing
 */
function updateDataLayer(dataLayerObj, index, action, replacementId, $productTile) {
    var dataLayer = dataLayerObj;
    var item = dataLayer.ecommerce.items[0];
    var searchKeyword = $('.no-results-label .search-keywords').text().trim();
    item.index = index || 1;
    var itemListId = null;
    var itemListName = null;
    if (action) {
        itemListName = ITEM_LIST_NAMES[action];

        switch (action) {
            case 'plp':
                itemListId = `${ITEM_LIST_IDS.plp} ${item.item_category}`;
                break;
            case 'pdp':
                itemListId = `${ITEM_LIST_IDS.pdp} ${item.id}`;
                break;
            case 'home':
                itemListId = ITEM_LIST_IDS.home;
                break;
            case 'searchResults':
                itemListId = `${ITEM_LIST_IDS.searchResults} ${searchKeyword}`;
                break;
            case 'frequentlyPurchased':
                itemListId = ITEM_LIST_IDS.frequentlyPurchased;
                break;
            case 'replacement':
                itemListId = `${replacementId}`;
                break;
            case 'citrus':
                itemListId = $productTile.attr('data-item-list-id');
                itemListName = $productTile.attr('data-item-list-name');
                break;
            default:
                break;
        }
    }
    if (dataLayer) {
        dataLayer.ecommerce.item_list_id = itemListId;
        if (itemListName) {
            dataLayer.ecommerce.item_list_name = itemListName;
        }
        window.dataLayer.push(dataLayer);
    }
}

$body.on('product:afterAddToCart', function (e, data) {
    if (data && data.dlEventAddToCart) {
        window.dataLayer.push(data.dlEventAddToCart);
    }
});

$body.on('product:afterRemoveFromCart', function (e, data) {
    if (data && data.dlEventRemoveProduct) {
        window.dataLayer.push(data.dlEventRemoveProduct);
    }
});

$body.on('cart:update', function (e, data) {
    if (data && data.dlEventUpdateCart) {
        window.dataLayer.push(data.dlEventUpdateCart);
    }
});

$body.on('minicart:show', function (e, data) {
    if (data && data.dlEventShowMiniCart) {
        window.dataLayer.push(data.dlEventShowMiniCart);
    }
});

$body.on('checkout:addShipping', function (e, data) {
    if (data && data.dlEventAddShippingInfo) {
        window.dataLayer.push(data.dlEventAddShippingInfo);
    }
});

$body.on('checkout:addPayment', function (e, data) {
    if (data && data.dlEventAddPaymentInfo) {
        window.dataLayer.push(data.dlEventAddPaymentInfo);
    }
});

$body.on('citrus:viewItemList', function (e, data) {
    if (data) {
        window.dataLayer.push(data);
    }
});
$body.on('citrus:viewInsert', function (e, name) {
    if (name) {
        window.dataLayer.push({
            event: 'view_insert',
            insert_type: 'citrus',
            insert_name: name
        });
    }
});

$('body').on('click', '.js-product-tile-gtm', function (event) {
    var $this = $(event.currentTarget).closest('.js-product-tile');
    var index = parseInt($this.closest('.js-gtm-select-promotion-event').data('index'), 10);
    var pageType = $page.data('page-type');
    var action = $page.data('action');
    var citrusAdId = $this.attr('data-citrusad-id');
    action = pageType || (action ? action.split('-').join('').toLowerCase() : '');

    if (citrusAdId) {
        action = 'citrus';
    }

    var selectPromotionDataLayerObj = $this.data('select-promotion-event-object');
    var replacementId = $this.attr('data-item-list-id');
    if (selectPromotionDataLayerObj) {
        updateDataLayer(selectPromotionDataLayerObj, index + 1, action, null, $this);
    }
    var selectItemDataLayerObj = $this.data('select-item-event-object');
    if (selectItemDataLayerObj) {
        if (parseInt(replacementId, 10)) {
            action = 'replacement';

            var tileWrapper = $this.parents('.js-product');

            index = $('.replacement-products').find(tileWrapper).index(); // gets the replacement tile position on dekstop

            if (!index || index < 0) {
                tileWrapper = $this.parents('.owl-item');

                index = $('.replacement-products .owl-stage').find(tileWrapper).index(); // gets the replacement tile position on mobile
            }
        }

        updateDataLayer(selectItemDataLayerObj, index + 1, action, replacementId, $this);
    }
});

$('.js-gtm-nav-cta').on('click', function () {
    const gtmNavCta = $(this).attr('data-gtm-nav-cta');

    if (window.dataLayer && gtmNavCta) {
        window.dataLayer.push({
            event: 'top_nav',
            top_nav_cta: gtmNavCta
        });
    }
});

const initProductDetailView = () => {
    const $productDetailView = $('.product-detail');
    if ($productDetailView && $productDetailView.length > 0) {
        var $analyticsElement = $productDetailView.find('meta[itemprom=analytics].js-pdp-analytics');
        if ($analyticsElement) {
            var data = $analyticsElement.attr('content');
            if (data) {
                window.dataLayer.push(JSON.parse(data));
            }
        }
    }
};

const init = () => {
    initProductDetailView();
};

init();
